import { useSelector } from "react-redux";
import { FetchPaymentList } from "../../../Api";
import { RootState } from "../../../redux/store";
import styles from "./wrapper/wrapper.module.css";
import { useEffect, useState } from "react";
import { cdn_Link } from "../../../config";

const PaymentBill = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [data, setdata] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchPayment = () => {
    FetchPaymentList({ cookie })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setdata(x);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  return (
    <>
      <div className={styles.mainContiner}>
        <table>
          <br />
          <tr>
            <th>Coach Name</th>
            <th>Coach Email</th>
            <th>Total Session</th>
            <th>Total Programs</th>
            <th>Total Revenue</th>
          </tr>
          {data.history.map((elem: any, ind: number) => (
            <tr>
              <td>
                {" "}
                <img
                  src={`${cdn_Link}/${elem?.coachProfileImg}`}
                  alt=""
                  style={{
                    height: "3rem",
                    width: "3rem",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                />
                {elem.coachName}
              </td>
              <td>{elem?.coachEmail}</td>
              <td>{elem?.totalsessions}</td>
              <td>{elem?.programData.length}</td>
              <td>
                {elem?.programData.reduce((sum: number, chp: any) => {
                  return sum + chp.overallCost;
                }, 0)}
              </td>
            </tr>
          ))}
        </table>
      </div>
      {/* "_id": "66c83042dbcc2c77ad997d6d",
                "coachName": "abcd user",
                "coachEmail": "abcd12@gmail.abcd",
                "coachProfileImg": "profile_66c83042dbcc2c77ad997d6d.jpeg",
                "totalDuration": 240,
                "programList": [
                    "66d845b2bd34bd8ec1081d4e"
                ],
                "totalsessions": 4,
                "programData": [
                    {
                        "_id": "66d845b2bd34bd8ec1081d4e",
                        "overallCost": 28,
                        "programImg": "Group_4H8X1K6A3E8B7X9A6V1R.jpeg"
                    }
                ] */}
    </>
  );
};
export default PaymentBill;
