import { useEffect, useState } from "react";
import style from "../../../../styles/setting.module.css";
import { FetchCoachInfo } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
const SetTab4 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [data, setData] = useState({
    Mprocess: "",
    Wprocess: "",
  });

  const fetchCoachDetails = () => {
    FetchCoachInfo(cookie)
      .then((result: any) => {
        const { Mprocess, Wprocess }: any = result.data;
        setData({ Mprocess, Wprocess });
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    fetchCoachDetails();
  }, []);

  return (
    <>
      <div className={style.tabcontainer}>
        <div className={style.tabheader}>Administration</div>
        <div className={style.tab4container}>
          <textarea
            placeholder="Process to maintain client confidentiality"
            value={data.Mprocess}></textarea>

          <textarea
            placeholder="What process do you use to follow timely contractor required submissions (i.e., invoices, reports…)style"
            value={data.Mprocess}></textarea>
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab4;
