
import styles from "./programs.module.css";
import style from "../wrapper/wrapper.module.css";
import TimerIcon from '@mui/icons-material/Timer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {  CreateSession, fetchChapter, fetchPrgId } from "../../../../Api";
import Modal from "@mui/material/Modal";
import { Box, TextField, Typography } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import { notifySuccess } from "../../../../Notify";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  programId: any;
  DataForm:any
};

const OnlineProgram = ({ setActivescreen ,programId,DataForm}: propType) => {
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [open, setOpen] = useState(false);
  const handleOpen1 = () => setOpen(true);
  const handleClose1 = () => setOpen(false);
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.profileSetup);
  const cookie: any = user?.token;
  const [program, setProgram] = useState<string[]>([]);
  const [pname,setPname]=useState("");
  const[overcost,setOvercost]=useState("")
  const[createdAt,setCreate]=useState("");
  const[desc,setDesc]=useState("")
  const [coachNames, setCoachNames] = useState<string[]>([]);
  const[programImg,setprogramImg]=useState('')
  const [previewImage, setPreviewImage] = useState("");
const[title,setTitle]=useState("");
const[expected,setExpected]=useState("");
const[duration,setDuration]=useState("");
const[chapter,setChapter]=useState<string[]>([]);
const[upd,setUpd]=useState(false)
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    console.log("files", file);

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };
  console.log(DataForm )
  useEffect(() => {
    fetchPrgId(cookie,programId)
      .then((result: any) => {
        console.log("Sile Data  data:", result);
        setProgram(result.data.history);
        setPname(result.data.name);
        setOvercost(result.data.overallCost);
        setCreate(result.data.createdAt.substring(0,10));
        setDesc(result.data.description);
        setCoachNames(result.data.coacheIds);
        setprogramImg(result.data.programImg)
        console.log("uid Data ", program);

      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        
      });
  }, [cookie,upd]);
  const pId=programId;
  useEffect(() => {
    fetchChapter(programId,cookie)
      .then((result: any) => {
        console.log("Data of chapter",result);
        setChapter(result.data.history)
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        
      });
  }, [cookie,upd])
const handleSession=(e:any)=>{
e.preventDefault();
CreateSession(pId,title,expected,duration,cookie)
.then((result: any) => {
  setUpd(prev => !prev);
  notifySuccess(result.data.message)
  handleClose();
  setTitle('')
  setExpected('')
  setDuration('')
  handleClose1();
})

.catch((error) => {
  console.log(error);
  setTitle('')
  setExpected('')
  setDuration('')
});
  // fetchChapter

}
const handleSubmit=()=>{}
  return(<>
    <div className={styles.recorded}>
    
<div className={styles.EditLeft}>
{programImg? (<img  src={`https://dxe2g9i1k0e2c.cloudfront.net/${programImg}`} className={styles.EditData}/>):
                        (<p className={styles.EditData}>{pname.slice(0, 2)}</p>                        
                          )}

<div className={styles.EditLeftData}>
  <h5>{pname} </h5>
  <p className={styles.EditLeftDataP1}><span><TimerIcon/></span>{createdAt} </p>
  <p className={styles.EditLeftDataP2}><span><AttachMoneyIcon/></span>${overcost}</p>
</div>
</div>
<div className={styles.EditRightData}>
  
 

 
 <div className={style.addButton}>
            <div className={styles.upgradeButton}>
              
              <button className={styles.EditRightBtn1} onClick={handleOpen}><BorderColorIcon/>Edit</button>
              <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
              >
                <Box className={style.BoxStyle}>
                  <span onClick={handleClose} className={style.HandleClose}>
                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                   <h3> Edit Program</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={style.typoDesc}
                  >
                     <div className={style.ProgramProp}>
                    <div className={style.onlinePro} >
                    <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
                onChange={handleProfile}
              id="fileInput"
              name="profile"
            />

            {!previewImage ? (
              <>
                <img
                  src="/images/Admin/unnamed.jpg"
                  className={style.progProfile}
                />
                <div className={style.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    // onClick={handleProfileClick}
                  />
                </div>
              </>
            ) : (
              <>
                <img
                  src={previewImage}
                  alt="Preview"
                  className={style.MainImgPre}
                />
                <div className={style.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    // onClick={handleProfileClick}
                  />
                </div>
              </>
            )}
                  <form className={style.programForm}  onSubmit={handleSubmit}>
                    <TextField
            type="text"
            id="outlined-basic"
            label="Program Topic"
            variant="outlined"
            name="name"
            // value={name}
            // onChange={(e: any) => setName(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${style.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
          
          <TextArea
            id="outlined-basic"
            placeholder="Description"
            name="description"
           
            // value={description}
            // onChange={(e: any) => setDescription(e.target.value)}

            rows={5}
          />
           <TextField
            type="text"
            id="outlined-basic"
            label="Overall Cost"
            variant="outlined"
           
            name="overallCost"
            // value={overallCost}
            // onChange={(e: any) => setoverallCost(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
            }}
            sx={{
              
              my: 1,
              borderRadius: "20px",
              "& .MuiInputBase-input": {
                width: "100%",
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
             <button  className={style.SaveButton}>
              Update
           
            </button>
       
            </form>
          
                      </div> 
                    
             
                     </div>
                  </Typography>

                  </Box>
                  </Modal>
                  </div>
                  </div>
 <button className={styles.EditRightBtn2}><DeleteIcon/>Delete</button>
 
</div>

    </div>
    <div className={styles.EditDescription}>
    <hr/>
    <br/><br/>
      <h5>Description</h5>
      
<p>{desc}
</p>
</div>
<div>
  <h5>Added Coaches</h5>
 

  <div className={styles.coachData}>
  {coachNames.map((coach:any, index:any) => (
     <div className={styles.coachContainer}>
      
      <img src="/images/Coachee/p1.png" className={styles.coachImg2} alt="Coach" />
     
      <div className={styles.coachName}>
      <h6>{coach.name}</h6><p>{coach.email}</p>
     
        </div>
      </div>
      
  ))}
  </div>
</div>
<br/>
<br/>
<div className={styles.recorded}>

  <h5>Total Session</h5>
  <button className={styles.EditRightBtn1} onClick={handleOpen1}><AddBoxSharpIcon/> Add New Session</button>

  <Modal
                open={open}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
              >
                <Box className={style.BoxStyle}>
                  <span onClick={handleClose1} className={style.HandleClose}>
                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                   <h3> Create New Session</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={style.typoDesc}
                  >
                     <div className={style.ProgramProp}>
                    <div className={style.onlinePro} >
                   
                  <form className={style.programForm}  onSubmit={handleSession}>
                    <TextField
            type="text"
            id="outlined-basic"
            label="Session Topic"
            variant="outlined"
            name="title"
           value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${style.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
          
          <TextArea
            id="outlined-basic"
            placeholder="What are you going to learn"
            name="description"
               value={expected}
            onChange={(e: any) => setExpected(e.target.value)}
            

            rows={5}
          />
           <TextField
            type="number"
            id="outlined-basic"
            label="Expected Duration of Session"
            variant="outlined"
            name="duration"
              value={duration}
            onChange={(e: any) => setDuration(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
            }}
            sx={{
              
              my: 1,
              borderRadius: "20px",
              "& .MuiInputBase-input": {
                width: "100%",
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />

         
             <button  className={style.SaveButton}>
              Save
           
            </button>
  
            </form>
                      </div> 
                    
             
                     </div>
                  </Typography>

                  </Box>
                  </Modal>
                
</div>
<div>
<br/>
<table>
  <tr>
    <th>SESSION TOPIC</th>
    <th>WHAT ARE WE GOING TO LEARN</th>
    <th>SESSION DURATION</th>
    <th>DATE</th>
    <th></th>
  </tr>
  
  {chapter.length > 0 ? (
              chapter.map((lesson: any, index: number) => (<>
            <tr> <td className={styles.groupIcTd}><span className={styles.groupIc}>{lesson.title.substring(0,2)}</span> {lesson.title.slice(0, 15)} </td>
            <td>{lesson.expected.slice(0, 35)}</td>
            <td>{lesson.duration}</td>
            <td>{lesson.createdAt.substring(0,10)}</td>
            <td className={styles.editIcon}>
<img src="/images/svg/Admin/viewIcon.svg"/>
                      <img src="/images/svg/Admin/EditIcon.svg" />
                      <img src="/images/svg/Admin/deletIcon.svg"   />
</td>
            </tr>
              </>
          ))
        ) : (
          <p>No coach data available</p>
        )}
  
</table>
</div>

  </>)
}
export default OnlineProgram;
