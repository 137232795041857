import CardSlider from "./Model/CardSlider";
import style from "./wrapper/landing.module.css";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};

const Services = ({ setActiveTab }: propType) => {
  return (
    <>
      <div className={style.HomeContainer}>
        <div className={style.headerContainer}>
          <div className={style.ServiceHead}>
            <div className={style.ServiceLeft}>
              <span className={style.ServiceSpan}>Our Services</span>
              <h4>lorem ipsom is simple that dummy text that can used</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and type
                setting industry. has been the industry standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to on people goes under takern goes on that can
                
              </p>
            </div>
            <div className={style.ServiceRight}>
              <img src={`./images/landing/ServiceHead.png`} className={style.HeaderImgIc} alt=""/>{" "}
            </div>
          </div>
        </div>
        <div className={style.OurMsVs}>
          <div className={style.UpComingSect}>
            <div className={style.WhatFeatureContent}>
              <div className={style.ServiceHeadContent}>
                <span className={style.WFSpan}>Our services</span>
                <h4>
                  <b>Lorem ipsom is simple dummy text that can goes all kind</b>
                </h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and type
                  setting industry has been the industry standard simply dummy
                  text of the printing and type setting text ever since the
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*                     Services           */}

        <div className={style.CardContentContainer}>
        <div className={style.cardsMasterContent}>
          <div className={style.CardContent}>
            <div className={style.CardDesign1} data-id="Card1">
              <div className={style.CardHeader}>
                <img
                  src="./images/landing/firstLogo.png"
                  className={style.OfferICon1} alt=""
                />
                <span>01</span>
              </div>
              <h6>Service title here</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card2">
              <div className={style.CardHeader}>
                <img
                  src="./images/landing/twoLogo.png"
                  className={style.OfferICon1} alt=""
                />{" "}
                <span>02</span>
              </div>
              <h6>Schedule appointment</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card1">
              <div className={style.CardHeader}>
                <img
                  src={`./images/landing/threeLogo.png`}
                  className={style.OfferICon1} alt=""
                />
                <span>03</span>
              </div>
              <h6>Service title here</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card2">
              <div className={style.CardHeader}>
                <img
                  src="./images/landing/fourLogo.png"
                  className={style.OfferICon1} alt=""
                />
                <span>04</span>
              </div>
              <h6>Get your Certificate</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
          </div>
          </div>
          <div className={style.cardsMasterContent}>
          <div className={style.CardContent}>
            <div className={style.CardDesign1} data-id="Card1">
              <div className={style.CardHeader}>
                <img
                  src="./images/landing/fiveLogo.png"
                  className={style.OfferICon1} alt=""
                />
                <span>05</span>
              </div>
              <h6>Find Favorites Coaches</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card2">
              <div className={style.CardHeader}>
                <img
                  src="./images/landing/sixLogo.png"
                  className={style.OfferICon1} alt=""
                />{" "}
                <span>06</span>
              </div>
              <h6>Schedule appointment</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card1">
              <div className={style.CardHeader}>
                <img
                  src={`./images/landing/sevenLogo.png`}
                  className={style.OfferICon1} alt=""
                />
                <span>07</span>
              </div>
              <h6>Join Webinars</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
          </div>
          </div>
         
        </div>
        <div className={style.UpcomingSection1}>
          <div className={style.UpComingSect1}>
            <div className={style.LeftUpcome1}>
              <img src="/images/landing/UpcomingImg.png" alt=""/>
            </div>
            <div className={style.RightUpcome}>
              <h6 className={style.DsbsBlue}>Who we are</h6>
              <h3 className={style.DsbsRBlue}>
                What In The World Are
                <span className={style.DsbsBlue}>
                  <br />
                  Dream Smart Solution?
                </span>
              </h3>
              <p>
                Your Behavioral SuperPowers are what make you unique. DreamSmart
                Academy (DSA), believes everyone has a unique set of Behavioral
                Fingerprints. DSA refers to your unique fingerprints as your
                Behavioral SuperPowers.
              </p>
              <p>
                Your Behavioral SuperPowers consist of discovery exercises that
                consider one’s living environments, expectations,(good or bad),
                life experiences, education, passions, values, behavioral
                talents, communication preferences, and financial temperament.
              </p>
            </div>
          </div>
        </div>
        <div>
          <CardSlider/>
        </div>
      </div>
    </>
  );
};

export default Services;
