import { json } from "stream/consumers";
import { Axios } from "./Axios";
import { number } from "yup";

// All of the APIS will be declared over here within a function that return promise.
const triggerBackgroundAuthentication = (token: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/background-authentication`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve("");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const LoginApi = (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/login`,
      {
        email: email,
        password: password,
      },
      {
        //  withCredentials:true
      }
    )

      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const googleLoginapi = ({ access_token, role }: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/googlelogin`,
      {
        access_token: access_token,
        role: role,
      },
      {
        //  withCredentials:true
      }
    )
      .then((result) => {
        console.log(result.data);
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const VerifyApi = (concatValue: any, id: string, reqtype: string) => {
  console.log("data is present:", concatValue, id, reqtype);
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/verify`,
      {
        otp: concatValue,
        refId: id,
        reqtype: reqtype,
      },
      {}
    )
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ResetPass = (newPassword: any, cPassword: any, cookie: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/reset-password`,
      {
        newPassword: newPassword,
        cPassword: cPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const SignUpApi = (
  name: string,
  email: string,
  password: string,
  role: number
) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/signup`,
      {
        name: name,
        email: email,
        password: password,
        role: role,
      },
      {}
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ContactInfo = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/coach/contactInfo`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Successfully fetched data!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ContactFormData = (formData: FormData) => {
  return new Promise((resolve, reject) => {
    Axios.post("/api/v1/contactUs", formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const blogs = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/api/v1/blogs", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Successfully Fetch Blog data");
      })
      .catch((error) => {
        reject(error);
        console.error("Error saving contact data:", error);
      });
  });
};

const fetchblogs = (bId: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/blogs-details?bId=${bId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Successfully Fetch Blog data");
      })
      .catch((error) => {
        reject(error);
        console.error("Error saving contact data:", error);
      });
  });
};
const fetchContactUs = (cookie: string, page: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/contactUs`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const ContactInfoPatch = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coach/contactInfo`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Successfully fetched data!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const certificates = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coach/certificates`,
      formData,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const certificatesPatch = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/certificates`,
      formData,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const coachexp = (
  yearExp: number,
  style: string,
  model: string,
  perfect: string,
  challenges: string,
  currentprofession: string,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coach/coachexp`,
      {
        yearExp,
        style,
        model,
        perfect,
        challenges,
        currentprofession,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const coachexpPatch = (
  yearExp: number,
  style: string,
  model: string,
  perfect: string,
  challenges: string,
  currentprofession: string,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/coachexp`,
      {
        yearExp,
        style,
        model,
        perfect,
        challenges,
        currentprofession,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const administration = (Mprocess: string, Wprocess: string, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coach/administration`,
      {
        Mprocess,
        Wprocess,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const bankDetails = (
  bankaccount: any,
  ssNumber: string,
  bankToken: string,
  hourlyRates: string,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/bank-details`,
      { bankaccount, ssNumber, bankToken, hourlyRates },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchbankDetails = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/bank-details`, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const administrationPatch = (
  Mprocess: string,
  Wprocess: string,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/administration`,
      {
        Mprocess,
        Wprocess,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CoacheePersonalInfo = (formData: any, cookie: string) => {
  console.log("data", formData);
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coachee/personalInfo`, formData, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// fetchAdmin
const fetchAdmin = (cookie: string, filter: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/fetchallusers?role=${filter}`,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoach = (cookie: string, filter: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/fetchallusers?role=${filter}`,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchUsersId = (cookie: string, uid: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/getUser`, uid, {
      headers: {
        Authorization: `Bearer ${cookie}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const OfflineProgramData = (formData: any, cookie: string) => {
  console.log("offline Data", formData);
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/offline_program`, formData, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchPrgId = (cookie: string, pid: any) => {
  console.log("id of program", cookie);
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/program/${pid}`, {
      params: {
        // role and page and search
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const AddCard = ({ cToken, cookie }: { cToken: any; cookie: string }) => {
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/coachee/card`,
      { cToken },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCard = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/card`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const fetchCoachGroup = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/joinedGroup`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const FetchPaymentList = ({ cookie }: { cookie: string }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/payments`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchSessionAdmin = ({
  cookie,
  filter,
}: {
  cookie: string;
  filter: number;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/session?filter=${filter}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchCoacheeGroup = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/group`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoacheeGroupId = (cookie: string, groupId: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/group/${groupId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoachGroupID = (groupId: any, cookie: string) => {

  return new Promise((resolve, reject) => {

    Axios.get(`/api/v1/coach/fetchgroup?groupId=${groupId}`, {

      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch(err => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
const checkRecommend = (groupId: any, cookie: string) => {

  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/fetchgroup?groupId=${groupId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoachprogramByID = (cId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/program-enrolled-coach?cId=${cId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const createOffProgram = (
  progId: any,
  cardId: any,
  appType: any,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/coachee/enroll-program`,
      { pId: progId, cardId: cardId, appType: appType },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });

}
const upgradePlans = (cardId:any, cookie: string) => {
  return new Promise((resolve, reject) => {

    Axios.post(`/api/v1/coachee/upgrade-profile`,
      {cardId:cardId},
      { headers: { Authorization: `Bearer ${cookie}` } })
      .then(result => {

        resolve(result.data);
        console.log("successfully Plan Upgraded!!")
      })
      .catch(err => {

        reject(err);

      })
  });

}

const createOnProgram = (progId: any,cardId:any,coachIdMatch:any,appType:any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coachee/enroll-program`,
      { pId: progId, cardId: cardId, coach_Id: coachIdMatch, appType: appType },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const CreateProgram = (formData: any, cookie: string) => {
  console.log("Online Data", formData);
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(`/api/v1/admin/program`, formData, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateSession = (
  pId: any,
  title: any,
  expected: any,
  duration: any,
  cookie: string
) => {
  console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/admin/chapter`,
      { pId: pId, title: title, expected: expected, duration: duration },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const CreateOffSession = (
  programId: any,
  title: any,
  expected: any,
  durat: any,
  resource: any,
  cookie: string
) => {
  console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/admin/chapter`,
      {
        pId: programId,
        title: title,
        expected: expected,
        duration: durat,
        resource: resource,
      },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoachByCoachee = (page:number,cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/fetch_coachs`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const CreateFAQ = (question: string, answer: string, cookie: string) => {
  console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/admin/faq`,
      { question: question, answer: answer },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const UpdateFAQ = (
  gid: any,
  question: string,
  answer: string,
  cookie: string
) => {
  console.log("Online Data");
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/admin/faq`,
      { _id: gid, question: question, answer: answer },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);

      })
  });

}
const UpdateCoachPersonalInfo = (fname: any, lname: string,mono:string,address:string,profile:any , cookie: string) => {
  
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coachee/personalInfo`,
      { firstName: fname, lastName: lname, mono: mono,location:address,profile:profile },
      { headers: { Authorization: `Bearer ${cookie}` } })
      .then(result => {

        resolve(result.data);
        console.log("successfully data fetch to Offline course!!")
      })
      .catch(err => {

        reject(err);

      })
  });

}
const fetchFAQ = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/faq`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const DeleteFAQ = (gid: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/faq`, {
      data: {
        _id: gid,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Successfully deleted group with ID:", gid);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const ForgetPassEmail = (userinput: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/forget-password`, { userinput: userinput })
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const ResetOtp = (refId: string, types: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/resendOtp`, { refId: refId, type: types })
      .then((result) => {
        resolve(result.data);
        console.log("OTP SEND");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchAgroup = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/group`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchChapter = (pId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/chapter?pId=${pId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchProgramForCoach = (
  filter: any,
  program_type: any,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program?filter=${filter}&program_type=${program_type}`,
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}

const fetchRecommendCoach = (filter: any, cookie: string,page:number) => {

  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/recommended-coaches?filter=${filter}`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        console.log(result.data);
      })
      .catch(err => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
// admin/blog
const FetchBlogs = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/blog`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
const GetUncoveringSession = (cookie: string) => {

  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/uncovering-session`, {
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        console.log("Group Data", result.data);
      })
      .catch(err => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
const CreateNewGroup = (groupTopic: any, coachIds: any, coacheeIds: any, cookie: string) => {


  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/admin/group`,
      { groupTopic: groupTopic, coachIds: coachIds, coacheeIds: coacheeIds },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateNewBlog = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/blog`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const BookUncoveringSession = (coachId:any, cookie: string) => {


  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/coachee/uncovering-session`,{coachId:coachId} , {
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch(err => {
        reject(err);
      });
  });
};
const DeleteChapter = (cId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/chapter`, {
      data: {
        cId: cId,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const DeleteBlog = (id: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/blog`, {
      data: {
        _id: id,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Successfully deleted Blog with ID:", id);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// manageAvail
const manageAvail = (availabilityData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coach/manage-avail`, availabilityData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log(result.data);

        console.log("successfully data stored!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getmanageAvail = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/manage-avail`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const ApproveCoach = (uid: string, action: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/admin/actionCoach`,
      { uid: uid, action: action },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const DeleteCards = (cardId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/coachee/card`, {
      data: {
        cardId: cardId
      },
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        
      })
      .catch(err => {
        reject(err);
      });
  });
};
const DeleteGroup = (gid: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/group`, {
      data: {
        gid: gid,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Successfully deleted group with ID:", gid);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchAProgram = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/fetchallprograms`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchAgroupId = (gId: string, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/group-details?gId=${gId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("Group Detailed Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const fetchCoachDataForCoachee = (cookie: string, coachId: any) => {
  console.log("id of program", cookie);
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/coache-details?coachId=${coachId}`, {
      params: {
        // role and page and search
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const UpdateToken = (cookie: string, notifytoken: any) => {
  return new Promise((resolve, reject) => {
    console.log(notifytoken);

    Axios.patch(
      `/api/v1/notifytoken`,
      { notifytoken },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("Chat Initilized Success!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const InitilizeTicket = (cookie: string, title: any, description: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/ticket`,
      { title: title, description: description },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("Chat Initilized Success!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateSessionByCoachee = (
  coachId: string,
  starttime: string,
  endtime: string,
  appType: number,
  date: string,
  pId: string,
  cookie: string
) => {
  console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/coachee/schedule`,
      {
        coachId: coachId,
        starttime: starttime,
        endtime: endtime,
        appType: appType,
        date: date,
        pId: pId,
      },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);

      })
  });
}
const fetchCoacheeSession = (page:number,cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/schedule?filter=${1}`,

      {
        params: {
          // role and page and search
          page: page,
        },
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchCoacheeProgram = (cookie: string,progType:any,page:any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program?filter=${1}&program_type=${progType}`,

      {
        params: {
          // role and page and search
          page: page,
        },
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoacheeEnrollProgram = (cookie: string,program_type:any,page:number) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/joinedprogram?program_type=${program_type}`,

      {
        params: {
          // role and page and search
          page: page,
        },
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoacheeEnrollProgramDetails = (cookie: string, pid: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program-chapter-details?pid=${pid}`,

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoacheeProgramByID = (cookie: string, pid: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program-details?pid=${pid}`,

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const chapterMarkComplete = (pId:any,cId:any,cookie:any) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coachee/chapter-complete`,
      {pId:pId,cId:cId},

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Called To Get AllChatrooms
const GetTickets = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/ticket `, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// /admin/tickets
const GetAdminTickets = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/tickets?filter=1`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetAllChats = (cookie: string, chatId: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      // type1 for making chat with coach and coachee
      // Type 2 for making chat by admin with coach either coachee
      `/api/v1/chat?roomType=${1}`,
      { chatId },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const GetAllMsg = (cookie: string, chatId: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/getmsg?page=1`,
      { chatId },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data);
        console.log("successfully data fetch!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const SendMsg = (cookie: any, activechat: string, msg: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/sendmsg`,
      { chatId: activechat, text: msg },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        console.log("Msg Sent ");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchSessions = ({
  filter,
  search,
  cookie,
}: {
  filter: any;
  search: any;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/sessions?filter=${filter}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchAllSessions = ({
  filter,
  search,
  cookie,
}: {
  filter: any;
  search: any;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/all-sessions?filter=${filter}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchUcoveringSessions = ({
  filter,
  item_per_page,
  cookie,
}: {
  filter: any;
  item_per_page: any;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coach/uncovering?filter=${filter}&item_per_page=${item_per_page}`,
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const SetUncoveringSession = ({
  data,
  cookie,
}: {
  data: any;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/uncovering`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchCoachInfo = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/coach-details`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchProgramsCoach = ({
  search,
  cookie,
  page,
}: {
  search: any;
  cookie: string;
  page: number;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/program?page=${page}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export {
  // common
  triggerBackgroundAuthentication,
  LoginApi,
  SignUpApi,
  VerifyApi,
  googleLoginapi,
  ContactInfo,
  ForgetPassEmail,
  ResetPass,
  ResetOtp,
  //Admin
  fetchCoach,
  fetchAdmin,
  fetchUsersId,
  OfflineProgramData,
  fetchAgroup,
  CreateNewGroup,
  CreateNewBlog,
  DeleteBlog,
  fetchAProgram,
  CreateProgram,
  fetchPrgId,
  CreateSession,
  CreateOffSession,
  fetchAgroupId,
  fetchFAQ,
  CreateFAQ,
  DeleteFAQ,
  UpdateFAQ,
  AddCard,
  fetchCard,
  fetchCoachGroup,
  fetchCoachGroupID,
  fetchCoachByCoachee,
  fetchCoachDataForCoachee,
  ApproveCoach,
  FetchBlogs,
  GetAdminTickets,
  FetchPaymentList,
  FetchSessionAdmin,
  //Coach
  certificates,
  coachexp,
  administration,
  bankDetails,
  manageAvail,
  getmanageAvail,
  FetchSessions,
  FetchCoachInfo,
  FetchbankDetails,
  FetchAllSessions,
  FetchUcoveringSessions,
  SetUncoveringSession,
  FetchProgramsCoach,
  // coachee
  CoacheePersonalInfo,
  CreateSessionByCoachee,
  fetchCoacheeSession,
  fetchCoacheeProgram,
  fetchCoacheeProgramByID,
  fetchCoacheeEnrollProgram,
  fetchCoacheeEnrollProgramDetails,
  fetchCoacheeGroup,
  fetchCoachprogramByID,
  createOnProgram,
  createOffProgram,
  fetchCoacheeGroupId,
  fetchProgramForCoach,
  chapterMarkComplete,
  upgradePlans,
  checkRecommend,
  DeleteCards,
  fetchRecommendCoach,
  BookUncoveringSession,
  GetUncoveringSession,
  UpdateCoachPersonalInfo,
  // Ticket/Chat API
  InitilizeTicket,
  GetTickets,
  GetAllMsg,
  SendMsg,
  GetAllChats,
  fetchChapter,
  fetchContactUs,
  // FCM Token API
  UpdateToken,
  DeleteGroup,
  // Landing Page
  blogs,
  fetchblogs,
  ContactFormData,
};
