import { useEffect, useState } from "react";
import AsTab1 from "./all-session/AS-Tab1";
import AsTab2 from "./all-session/AS-Tab2";
import AsTab3 from "./all-session/AS-Tab3";
import AsTab4 from "./all-session/AS-Tab4";
import Style from "../../../../src/styles/session.module.css";
import { FetchSessions } from "../../../Api";
import { useSelector } from "react-redux";

const Session = () => {
  const user = useSelector((state: any) => state.user.user.token);
  const [activeTab, setActiveTab] = useState(1);
  let filter = activeTab;
  const [myTimeOut, setMytimeOut] = useState();
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchSessions = async () => {
    FetchSessions({filter, search, cookie:user})
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setData(x);
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
  };

  useEffect(() => {
    fetchSessions();
  }, [filter]);

  useEffect(() => {
    clearTimeout(myTimeOut);
    const timeout: any = setTimeout(() => {
      fetchSessions();
    }, 700);
    setMytimeOut(timeout);
  }, [search]);

  return (
    <>
      <div className={Style.Artist}>
        {/* {Tab Container} */}
        <ul className={Style.tabCnt1}>
          <li
            className={`${Style.tab1} ${activeTab === 1 && Style.tabActive1}`}
            onClick={() => setActiveTab(1)}>
            Upcoming Sessions
          </li>
          <li
            className={`${Style.tab1} ${activeTab === 2 && Style.tabActive1}`}
            onClick={() => setActiveTab(2)}>
            Completed Sessions
          </li>
          <li
            className={`${Style.tab1} ${activeTab === 3 && Style.tabActive1}`}
            onClick={() => setActiveTab(3)}>
            Manage Requests
          </li>
        </ul>
        {activeTab === 1 && (
          <AsTab1
            setActiveTab={setActiveTab}
            data={data}
            handleSearch={handleSearch}
            search={search}
          />
        )}
        {activeTab === 2 && (
          <AsTab2
            setActiveTab={setActiveTab}
            data={data}
            handleSearch={handleSearch}
            search={search}
          />
        )}
        {activeTab === 3 && <AsTab4 setActiveTab={setActiveTab} />}
      </div>
    </>
  );
};

export default Session;
