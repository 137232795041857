import { green } from "@mui/material/colors";
import styles from "./session.module.css";
import { colors } from "@mui/material";
import { Color } from "antd/es/color-picker";
import { Handshake } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { cdn_Link } from "../../../../config";
import dayjs from "dayjs";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  handleSearch: (val: string) => void;
  search: string;
  data: {
    prev: boolean;
    next: boolean;
    search: string;
    page: number;
    total_page: number;
    history: never[];
  };
};
const AsTab2 = ({ setActiveTab, data }: propType) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.container1}>
          <input
            className={styles.container2}
            type="text"
            placeholder="Which client are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
          <div>
            <table className={styles.sessiontable}>
              <tr>
                <th> CLIENT NAME</th>
                <th> TOTAL SESSION TAKEN</th>
                <th> LATE SESSION</th>
                <th> ENGAGEMENT LEVEL</th>
                <th> COMMENTS</th>
                <th> STATUS</th>
              </tr>
              {data.history.length > 0 &&
                data.history.map((elem: any, ind: number) => (
                  <tr>
                    <td className={styles.showinfoConte}>
                      <img
                        src={
                          elem.CoacheeDetails.profileImg
                            ? `${cdn_Link}/${elem.CoacheeDetails.profileImg}`
                            : "/images/Coach/pic1.png"
                        }
                        alt=""
                        className={styles.showCinfoImg}
                      />
                      <div className={styles.showCInfo}>
                        <span className={styles.showCinofP}>
                          {" "}
                          {elem?.CoacheeDetails?.name}
                        </span>
                        <span className={styles.showCinofProg}>
                          {elem?.ProgramData?.name}
                        </span>
                      </div>
                    </td>
                    <td>{elem?.sessions.length} Session</td>
                    <td>
                      {dayjs(elem?.Last_session_on).format(
                        "MMM DD, YYYY hh:mm A"
                      )}{" "}
                    </td>
                    <td>Engaged</td>
                    <td>View Comment</td>
                    <td>
                      <CheckCircleIcon />
                      Completed
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AsTab2;
