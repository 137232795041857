import { useEffect, useState } from "react";
import { fetchCoachDataForCoachee, fetchCoacheeGroup } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import style from "./CSS/group.module.css"
import SearchIcon from '@mui/icons-material/Search';
import ShowGroups from "./Program/ShowGroups";
import dayjs from "dayjs";
const MyGroups=()=>{
  
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const[group,setGroup]=useState<any[]>([])
    const[groupName,setgroupName]=useState('')
    const[grpId,setgrpId]=useState('')
    const[userState,setUserState]=useState(false)
    const[coachData,setCoachData]=useState<any[]>([])
    const[activeTab,setActiveTab]=useState(0)
    const[page,setPage]=useState(1);
    useEffect(()=>{
        fetchCoacheeGroup(cookie)
        .then((res:any)=>{
            console.log("this is Coachee Group",res)
            setGroup(res.data.history)
            
            setUserState(true)
        })
    },[cookie,page])
    const handlePreviousPage = async () => {
        if (page > 1) {
          await setPage(page - 1);
        }
      };
    
      const handleNextPage = async () => {
        if (group.length > 0) {
          await setPage(page + 1);
        }
      };

const handleId=(e:any,gid:any)=>{
    e.preventDefault();
    setgrpId(gid)
setActiveTab(1)
}

    return(<>
    {activeTab===0 && ( <div className={style.groupContent}>
        <div className={style.groupInputContent}>
   <SearchIcon/> <input type="text" placeholder="Which Client are you looking for" className={style.groupInput}/>
    </div>
    <div className={style.showGroups}>
        
        <table>
            <tr>
            <th>GROUP TOPIC</th>
            <th>ASSIGNED COACH</th>
            <th>TOTAL SESSION</th>
            <th>SESSION COMPLETED</th>
            <th>DATE</th>
            <th></th>
            </tr>
            {/*  */}
           
                {group.length>0?(group.map((gp:any)=>(
                
                    <tr>
                        <td className={style.groupTd} ><p className={style.groupTdpara}>{gp.groupTopic.slice(0,2)}</p><p>{gp.groupTopic}</p></td>
                    <td>{gp.coachIds.map((coach:any)=>(<><img src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`} className={style.coachImg}/>{coach.name}</>))}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{dayjs(gp.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
                    <td><img src="/images/svg/Admin/viewIcon.svg" onClick={(e:any)=>handleId(e,gp._id)} /></td>
                  
                    </tr>
                  
                ))):(<td rowSpan={2}>No Group Sessions Scheduled !</td>)}
             
        </table>
       
    </div>
    <div className={style.pagination}>
        <button onClick={handlePreviousPage} disabled={page === 1} >
          Previous
        </button>
        <button onClick={handleNextPage} disabled={group.length === 0}>
          Next
        </button>
      </div>
    </div>)}
    {activeTab === 1 && (<ShowGroups grpId={grpId}/>)}
   
    </>)
}
export default MyGroups;