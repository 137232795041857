import React, { useEffect, useState } from "react";
import style from "./css/Support.module.css";
import { GetAllMsg, GetAdminTickets, SendMsg } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import SendIcon from "@mui/icons-material/Send";

const SupportChat = () => {
  const [ticket, setticket] = useState<any>({ description: "", issue: "" });
  const [msg, setmsg] = useState<any>("");
  const [activechat, setactivechat] = useState<any>("");
  const [messages, setmessages] = useState<any>([]);
  const [viewTicket, setviewTicket] = useState<any>([]);
  const[updata,setUpdata]=useState(false)
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;

 

  const GetMsgs = async (chatId: any) => {
    setactivechat(chatId);
    
    try {
      const data: any = await GetAllMsg(cookie, chatId);
      setmessages(data.history);
      setUpdata(true)
   
     
      console.log("data of chatroom",data)
    } catch (error) {
      console.log("Error At Fetching Messages", error);
    }
  };

  const SendMsgs = async () => {
    
    try {
      const data = await SendMsg(cookie,activechat,msg);
      setmsg("");
      GetMsgs(activechat); 
    } catch (error) {
      console.log("Error At Sending Message", error);
    }
  };
  

  const FetchAllTickets = async () => {
    try {
      // GetAllChats
      const data: any = await GetAdminTickets(cookie);
      if (!data.data) {
        console.log("No Tickets");
      } else {
        setviewTicket(data.data.history);
        console.log("Tickets Set", data.length);
        console.log("ticket Data",data)
      }
    } catch (error) {
      console.log("Error At Fetching Tickets", error);
    }
  };

  useEffect(() => {
    FetchAllTickets();
  }, [cookie,updata]);
 
  return (
    <>

      <div className={style.ChatContainer}>
      <div className={style.Chathead}>
              <h4> Ticket</h4>
          
            
            </div>

       
              <div className={style.Chatparts}>
          
          <div className={style.ChatpartsLeft}>
            
            {viewTicket && viewTicket.length > 0 ? (
              <>
                {viewTicket.map((ticket: any) => (
                  <div
                    key={ticket.chatId}
                    onClick={() => {
                      GetMsgs(ticket.chatId)
                    }}
                    className={style.TicketDesc}
                  >
                       
            
                    <p>{ticket.title}</p>
                    <span>{ticket.description.slice(0,16)}</span>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={style.ChatpartsRight}>
            <div className={style.ChatPageHead}>
<h6>Name of Sender</h6>
<hr/>
            </div>
            <div className={style.ChatPage}>
              {messages && messages.length > 0 ? (
                <>
                  {messages.map((msg: any, index: number) => (
                    <div key={index} className={style.DisplayMsg}>
                      {msg.from === user?._id ? (
                        <div className={style.msgTextLeft}>
                          <p>{msg.text}</p>
                        </div>
                      ) : (
                        <div className={style.msgTextRight}>
                          <p>{msg.text}</p>
                        </div>
                      )}
                      {/* <p>{msg.from}</p> */}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className={style.SendMsgButton}>
              <input
                type="text"
                value={msg}
                onChange={(e: any) => setmsg(e.target.value)}
              />
              <button onClick={SendMsgs}>
                <SendIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportChat;
