import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Chip,
} from "@mui/material";
import FormData from "form-data";
import styles from "../../../../styles/setting.module.css";
import style from "../../../../styles/setting.module.css";
import { LocationOn } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ContactInfo, FetchCoachInfo } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { cdn_Link } from "../../../../config";
import dayjs from "dayjs";
import { date } from "yup";

interface NewUser {
  phoneNo: string;
  firstName: string;
  lastName: string;
  behaviourIdenty: string;
  topvalues: string;
  comvalues: string;
  location: {
    streetName: string;
    city: string;
    state: string;
    postalCode: string;
  };
  linkedinLink: string;
  birthDate: string;
  instagramLink: string;
  facebookLink: string;
  twitterLink: string;
  profile: File | null;
  videoLink: File | null;
}

interface response {
  type: number;
  message: string;
  data: any;
}
const SetTab1 = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const [newUser, setNewUser] = useState<NewUser>({
    firstName: "",
    lastName: "",
    phoneNo: "",
    behaviourIdenty: "",
    topvalues: "",
    comvalues: "",
    location: {
      streetName: "",
      city: "",
      state: "",
      postalCode: "",
    },
    linkedinLink: "",
    instagramLink: "",
    facebookLink: "",
    twitterLink: "",
    birthDate: "",
    profile: null,
    videoLink: null,
  });

  const cookie: any = user?.token;
  console.log("User Data: ",newUser.firstName);
  
  const [previewImage, setPreviewImage] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");

  // profile

  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleVideoClick = () => {
    const videoInput = document.getElementById("videoInput");
    if (videoInput) {
      videoInput.click();
    }
  };
  const handleVideo = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setNewUser({ ...newUser, videoLink: file });

      const videoURL = URL.createObjectURL(file);
      setPreviewVideo(videoURL);
    }
  };
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, profile: file });

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    var formData = new FormData();

    const behavioralIdentity: any = newUser.behaviourIdenty;
    const topTalents: any = newUser.topvalues;
    const commpower: any = newUser.comvalues;
    formData.append("phoneNo", newUser.phoneNo);
    formData.append("behavioralIdentity", behavioralIdentity);
    formData.append("topTalents", topTalents);
    formData.append("commpower", commpower);
    formData.append("birthDate", dayjs(newUser.birthDate).format("DD/MM/YYYY"));
    formData.append("location", JSON.stringify(newUser.location));
    formData.append("linkedinLink", newUser.linkedinLink);
    formData.append("instagramLink", newUser.instagramLink);
    formData.append("facebookLink", newUser.facebookLink);
    formData.append("twitterLink", newUser.twitterLink);
    formData.append("profile", newUser.profile);
    formData.append("videoLink", newUser.videoLink);

    ContactInfo(formData, cookie)
      .then((result: any) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCoachDetails = () => {
    FetchCoachInfo(cookie)
      .then((result: any) => {
        const {
          
          phoneNo,
          behavioralIdentity,
          topTalents,
          commpower,
          location,
          linkedinLink,
          instagramLink,
          facebookLink,
          twitterLink,
          profileImg,
          birthDate,
          videoLink,
        }: any = result.data;
        
        setNewUser({
          firstName: user!.name.split(" ")[0],
          lastName: user!.name.split(" ")[1],
          phoneNo,
          behaviourIdenty: behavioralIdentity,
          topvalues: topTalents,
          comvalues: commpower,
          location: {
            streetName: location.streetName,
            city: location.city,
            state: location.state,
            postalCode: location.postalCode,
          },
          linkedinLink,
          birthDate,
          instagramLink,
          facebookLink,
          twitterLink,
          profile: profileImg,
          videoLink,
        });
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    fetchCoachDetails();
  }, []);

  return (
    <div className={style.tabcontainer}>
      <header className={style.tabheader}>Edit Profile</header>
      <div
        style={{
          position: "relative",
          width: "5rem",
          padding: "4px",
          marginBottom: "6px",
        }}>
        <img
          src={
            `${cdn_Link}/${newUser.profile}` || "./images/Coach/shape-2x.png"
          }
          style={{ height: "8rem", width: "9rem", borderRadius: "25px" }}
        />
        <img
          src="/images/svg/Coach/setting/camera.svg"
          className={style.tab1img2}
        />
      </div>
      <div className={style.formcontent}>
        <form onSubmit={handleSubmit}>
          <div className={style.tab1formcontainer}>
            <div className={style.leftab1formcontainer}>
              <TextField
                type="text"
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                name="firstName"
                value={newUser.firstName}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="number"
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                name="phoneNo"
                value={newUser.phoneNo}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                label="Behavioral Superpowers Identity"
                variant="outlined"
                name="behaviourIdenty"
                value={newUser.behaviourIdenty}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="communication SuperPower"
                variant="outlined"
                name="comvalues"
                className={styles.Inputfield}
                value={newUser.comvalues}
                onChange={handleChange}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="city"
                variant="outlined"
                name="city"
                className={styles.Inputfield}
                value={newUser.location.city}
                onChange={(e) => {
                  setNewUser({
                    ...newUser,
                    location: { ...newUser.location, city: e.target.value },
                  });
                }}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />

              <TextField
                type="date"
                id="outlined-basic"
                label="LinkedIn Profile link"
                variant="outlined"
                name="birthDate"
                value={newUser.birthDate}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                label="LinkedIn Profile link"
                variant="outlined"
                name="linkedinLink"
                value={newUser.linkedinLink}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="facebook Profile link"
                variant="outlined"
                name="facebookLink"
                value={newUser.facebookLink}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>
            <div className={style.rightab1formcontainer}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={newUser.lastName}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Three Top Talents"
                variant="outlined"
                name="topvalues"
                value={newUser.topvalues}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />

              <TextField
                id="outlined-basic"
                label="streetName"
                variant="outlined"
                name="streetName"
                value={newUser.location.streetName}
                onChange={(e) => {
                  setNewUser({
                    ...newUser,
                    location: {
                      ...newUser.location,
                      streetName: e.target.value,
                    },
                  });
                }}
                sx={{
                  mr: 2,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
                InputProps={{
                  className: `${styles.muiInput}`,
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id="outlined-basic"
                label="state"
                variant="outlined"
                name="state"
                value={newUser.location.state}
                onChange={(e) => {
                  setNewUser({
                    ...newUser,
                    location: { ...newUser.location, state: e.target.value },
                  });
                }}
                sx={{
                  mr: 2,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
              />

              <TextField
                id="outlined-basic"
                label="postalCode"
                variant="outlined"
                name="postalCode"
                value={newUser.location.postalCode}
                onChange={(e) => {
                  setNewUser({
                    ...newUser,
                    location: {
                      ...newUser.location,
                      postalCode: e.target.value,
                    },
                  });
                }}
                sx={{
                  mr: 2,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                label="instagram Profile link"
                variant="outlined"
                name="instagramLink"
                value={newUser.instagramLink}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
              <TextField
                type="text"
                id="outlined-basic"
                label="Twitter Profile link"
                variant="outlined"
                name="twitterLink"
                value={newUser.twitterLink}
                onChange={handleChange}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  mr: 1,
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                }}
              />
            </div>
          </div>
          <div className={styles.ImgVideoContent}>
            <div className={styles.ProfileImage}>
              <input
                type="file"
                id="fileInput"
                name="profile"
                onChange={handleProfile}
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className={style.formbutton}>
            <button>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetTab1;
