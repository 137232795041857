import { useEffect, useState } from "react";
import style from "./wrapper/landing.module.css";
import { blogs as bg, fetchblogs } from "../Api";
import FirstPageIcon from '@mui/icons-material/FirstPage';
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};

const Blogs = ({ setActiveTab }: propType) => {
  const [showBlog, setShowBlog] = useState(1);
  const [showData, setShowData] = useState<any[]>([])
  const [bdata, setBdata] = useState('')
 const[title,setTitle]=useState('')
 const[desc,setdesc]=useState('')
 const[img,setimg]=useState('')
 const[content,setcontent]=useState('')
  const [showbg, setShowbgh] = useState<any[]>([])
  // fetchblogs
  const getId = (e: any, bId: any) => {
    e.preventDefault();
    setBdata(bId)
    // setUpdata(false)
    setShowBlog(2)
  }

  useEffect(() => {
    bg()
      .then((res: any) => {
        console.log(res)
        setShowData(res.data.history)
      })
  }, [])
  const oncli = (e: any) => {
    e.preventDefault()
    setShowBlog(1)
  }
  const encodedContent = encodeURIComponent(content);
  const dataUrl = `data:text/html;charset=utf-8,${encodedContent}`;
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const result:any = await fetchblogs(bdata);
        console.log('Fetched result:', result.data);
        setTitle(result.data.title)
        setdesc(result.data.description)
        setimg(result.data.img)
        setcontent(result.data.content)
        

    
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
  
    fetchBlogs();
  }, [bdata]);
  console.log('Showbg length:', showbg.length);
  return (
    <>
      {showBlog === 1 && (<>

        <div className={style.HomeContainer}>
          <div className={style.headerContainer}>
            <div className={style.BlogHeadr}>
              <div className={style.ServiceLeft1}>
                <span className={style.ServiceSpan}>Our Blogs</span>
                <h4>lorem ipsom is simple that dummy text that can used</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and type
                  setting industry. has been the industry standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to on people goes under takern goes on that can
                  help people had make a type specimen book got all kind people
                  attention under when an unknown printer took a galley of type
                  and scrambled it to on people goes until known finds on that can
                  lorem ipsom galley of type and scrambled sout got.
                </p>
              </div>
              <div className={style.BlogHeadrRight}>
                <img src={`./images/landing/blogerheader.png`} className={style.BlogHeadrRightImg} alt=""/>{" "}
              </div>
            </div>
          </div>

          <div className={style.BlogCardContentContainer}>
            <div className={style.CardContentContainer}>

              <h3>
                <b>Our Latest Blogs</b>

              </h3>
              <div className={style.cardsMasterContent}>
              <div className={style.CardContent}>
                {showData.length > 0 ? (showData.map((blogs: any) => (<>

                  <div className={style.CardDesignBlog}>
                    <div>
                      <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${blogs.img}`} className={style.BlogImge} alt=""/>
                      <h6>{blogs.title}</h6>
                      {blogs.description.slice(0, 140)}</div>
                      {blogs.description.length<130 ?(<>
                        <br/>
                        <br/> 
                        <br/>
                        <button className={style.blogBtn} onClick={(e: any) => getId(e, blogs._id)}>Read More</button>
                        </>):(<>
                        <button className={style.blogBtn} onClick={(e: any) => getId(e, blogs._id)}>Read More</button>
                      </>)}
                   

                  </div>



                </>))) : (<>No Blogs To Show</>)}
              </div>
            </div>
            </div>
          </div>
        </div>


      </>)}

      {showBlog == 2 && (<>

        <div className={style.HomeContainer}>
          <div className={style.headerContainer}>
            <div className={style.BlogHeadr}>
              <div className={style.ServiceLeft}>

                {/* <span className={style.ServiceSpan} >Our Blogs</span> */}
                <h4>{title}</h4>
                <p>
                  {desc}
                </p>
              </div>
              <div className={style.BlogHeadrRight}>
                <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${img}`} alt=""/>{" "}
              </div>
            </div>
          </div>

          <div className={style.BlogCardContentContainer}>
            <div className={style.CardContentContainer}>
            <div className={style.CardContentContainerinfor}>
            <h6 onClick={oncli}><FirstPageIcon/> back</h6>
            </div>

<iframe
      src={dataUrl}
     className={style.iframe}
       title="Content"
       
    />

            </div>
          </div>
        </div>
      </>)}
    </>
  );
};

export default Blogs;
