
import styles from "./programs.module.css";
import style from "../wrapper/wrapper.module.css";
import TimerIcon from '@mui/icons-material/Timer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Button } from "antd";
import { Box, duration, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import TextArea from "antd/es/input/TextArea";
import { CreateOffSession, fetchChapter, fetchPrgId } from "../../../../Api";
import { notifySuccess } from "../../../../Notify";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  programId: any;
  DataForm:any;
};
interface NewUser{
  pImg:File|null;
}
const OfflineProgram = ({ setActivescreen,programId,DataForm }: propType) => {
  const [program, setProgram] = useState<string[]>([]);
  const [pname,setPname]=useState("");
  const[overcost,setOvercost]=useState("")
  const[createdAt,setCreate]=useState("");
  const[desc,setDesc]=useState("")
  const [coachNames, setCoachNames] = useState<string[]>([]);
  const[programImg,setprogramImg]=useState('')
  const [previewImage, setPreviewImage] = useState("");
  //  title,expected,durat,resource
  const[title,setTitle]=useState("");
  const[expected,setExpected]=useState("")
  const[durat,setDurat]=useState("")
  const[resource,setResource]=useState('')
  const [open1, setOpen1] = useState(false);
  const[updata,setUpdata]=useState(false)
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [open, setOpen] = useState(false);
  const handleOpen1 = () => setOpen(true);
  const handleClose1 = () => setOpen(false);
  const[chapter,setChapter]=useState<string[]>([]);
  const[name,setName]=useState("");
  const[description,setDescription]=useState("");
  const[overallCost,setoverallCost]=useState("");
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.profileSetup);
  const cookie: any = user?.token;
  const [newUser, setNewUser] = useState<NewUser>({
    pImg: null,
  });
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, pImg: file });
    console.log("files for resource", file);
    

    const imageURL = URL.createObjectURL(file);
    setResource(imageURL)
    
  };
 
  useEffect(() => {
    fetchPrgId(cookie,programId)
      .then((result: any) => {
      
        setProgram(result.data.history);
        setPname(result.data.name);
        setOvercost(result.data.overallCost);
        setCreate(result.data.createdAt.substring(0,10));
        setDesc(result.data.description);
        setCoachNames(result.data.coacheIds);
        setprogramImg(result.data.programImg)
        console.log("uid Data ", program);
        setUpdata(true)

      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        
      });
      setUpdata(false)
  }, [cookie,updata]);

  useEffect(() => {
    fetchChapter(programId,cookie)
      .then((result: any) => {
        console.log("Data of chapter",result);
        setChapter(result.data.history)
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        
      });
  }, [cookie,updata])


  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  }; 
  const handlSubmit=(e:any)=>{
    e.preventDefault();
    alert("good morn")
  }
  const  handleTypoSubmit =(e:any)=>{
    e.preventDefault();
   CreateOffSession(programId,title,expected,durat,resource,cookie)
   .then((res:any)=>{
  
   setUpdata(true)
   handleClose1()
   notifySuccess(res.data.message)
   }) 
   
  console.log("create Chapter Data is ",programId,title,expected,durat,resource) 
  setUpdata(false)
  }

  return(<>
    <div className={styles.recorded}>
    
<div className={styles.EditLeft}>
{programImg? (<img  src={`https://dxe2g9i1k0e2c.cloudfront.net/${programImg}`} className={styles.EditData}/>):
                          (<p className={styles.EditData}>{pname.slice(0, 2)}</p>                        
                          )}
<div className={styles.EditLeftData}>
  <h5>{pname}</h5>
  <p className={styles.EditLeftDataP1}><span><TimerIcon/></span>{createdAt} </p>
  <p className={styles.EditLeftDataP2}><span><AttachMoneyIcon/></span>${overcost}</p>
</div>
</div>
<div className={styles.EditRightData}>

 
 <div className={style.addButton}>
            <div className={styles.upgradeButton}>
              
              <button className={styles.EditRightBtn1} onClick={handleOpen}><BorderColorIcon/>Edit</button>
              <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
              >
                <Box className={style.BoxStyle}>
                  <span onClick={handleClose} className={style.HandleClose}>
                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                   <h3> Edit Program</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={style.typoDesc}
                  >
                     <div className={style.ProgramProp}>
                    <div className={style.onlinePro} >
                
                  <form className={style.programForm}  onSubmit={handlSubmit}>
                    <TextField
            type="text"
            id="outlined-basic"
            label="Program Topic"
            variant="outlined"
            name="name"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${style.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
          
          <TextArea
            id="outlined-basic"
            placeholder="Description"
            name="description"
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}

            rows={5}
          />
           <TextField
            type="text"
            id="outlined-basic"
            label="Overall Cost"
            variant="outlined"
           
            name="overallCost"
            value={overallCost}
            onChange={(e: any) => setoverallCost(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
            }}
            sx={{
              
              my: 1,
              borderRadius: "20px",
              "& .MuiInputBase-input": {
                width: "100%",
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
             <button  className={style.SaveButton}>
              Update
           
            </button>
       
            </form>
          
                      </div> 
                    
             
                     </div>
                  </Typography>

                  </Box>
                  </Modal>
                  </div>
                  </div>
 <button className={styles.EditRightBtn2}><DeleteIcon/>Delete</button>
</div>

    </div>
    <div className={styles.EditDescription}>
    <hr/>
    <br/><br/>
      <h5>Description</h5>
      
<p>{desc}
</p>
</div>

<div className={styles.recorded}>
  <h5>Total Session</h5>
  <button className={styles.EditRightBtn1} onClick={handleOpen1}><AddBoxSharpIcon/> Add New Session</button>
  <Modal
                open={open}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
              >
                <Box className={style.BoxStyle}>
                  <span onClick={handleClose1} className={style.HandleClose}>
                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                   <h3> Create New Session</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={style.typoDesc}
                  >
                     <div className={style.ProgramProp}>
                    <div className={style.onlinePro} >
                    
                  <form className={style.programForm}  onSubmit={handleTypoSubmit }>
                    <TextField
            type="text"
            id="outlined-basic"
            label="Session Topic"
            variant="outlined"
            name="name"
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${style.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
          
          <TextArea
            id="outlined-basic"
            placeholder="What are you going to learn"
            name="description"
           
            value={expected}
            onChange={(e: any) => setExpected(e.target.value)}

            rows={5}
          />
           <TextField
            type="number"
            id="outlined-basic"
            label="Expected Duration of Session"
            variant="outlined"
            name="overallCost"
            value={durat}
            onChange={(e: any) => setDurat(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
            }}
            sx={{
              
              my: 1,
              borderRadius: "20px",
              "& .MuiInputBase-input": {
                width: "100%",
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />

          <div className={styles.Filetext}>
            <FileCopyIcon  className={styles.FileIcon}/>
            <h6>Upload File/Video</h6>
            <p className={styles.Filep}>Lorem ipsom is simple dummy text that can people can that <br/> can under tool so can goes <br/>
              dotted on hiligter ipsom so not needed.</p>
            {/* <TextField
            type="file"/> */}
            <input
              type="file"
              style={{ display: "none" }}
                onChange={handleProfile}
              id="fileInput"
              name="profile"
              multiple
            />
           <p  className={style.SaveButton1}  onClick={handleProfileClick}>Select File</p>
           <p>{previewImage?<>File Select</>:<></>}</p>
          </div>
             <button  className={style.SaveButton}>
              Save
           
            </button>
       
            </form>
          
                      </div> 
                    
             
                     </div>
                  </Typography>

                  </Box>
                  </Modal>
                  
</div>
<br/>
<table>
  <tr>
    <th>SESSION TOPIC</th>
    <th>WHAT ARE WE GOING TO LEARN</th>
    <th>SESSION DURATION</th>
    <th>DATE</th>
    <th></th>
  </tr>
  
  {chapter.length > 0 ? (
              chapter.map((lesson: any, index: number) => (<>
            <tr> <td ><span className={styles.groupIc}> {(lesson.title.split(" ").length>1?(lesson.title.split(" ").map((word:any) => word.charAt(0)).join("").substring(0,2) ):(lesson.title.slice(0,2)))}</span> {lesson.title} </td>
            <td>{lesson.expected}</td>
            <td>{lesson.duration}</td>
            <td>{lesson.createdAt.substring(0,10)}</td>
            <td className={styles.editIcon}>

                      <img src="/images/svg/Admin/EditIcon.svg" />
                      <img src="/images/svg/Admin/deletIcon.svg"   />
</td>
            </tr>
              </>
          ))
        ) : (
          <p>No coach data available</p>
        )}
  
</table>
  </>)
}
export default OfflineProgram;
