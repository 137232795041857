import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import dayjs from "dayjs";
import { cdn_Link } from "../../../config";
import { FetchProgramsCoach } from "../../../Api";
import style from "../../../styles/program.module.css";

const Programs = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [programData, setprogramData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchProgram = () => {
    FetchProgramsCoach({
      cookie,
      page: programData.page,
      search: programData.search,
    })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...programData };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setprogramData(x);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  return (
    <div className={style.ProContainer}>
      <div className={style.CardContainer}>
        {programData.history.length > 0 &&
          programData.history.map((pdata: any) => (
            <div className={style.SingleCard}>
              <img
                src={`${cdn_Link}/${
                  pdata.programImg || pdata.Program_Details?.programImg
                }`}
                className={style.ProImage}
              />

              <div className={style.DetailContainer}>
                <p
                  style={{
                    color: "black",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}>
                  {pdata.name || pdata.Program_Details?.name}
                </p>
                <div>
                  <p style={{ color: "#0C5899" }}>
                    <CheckCircleIcon />{" "}
                    {dayjs(pdata.createdAt.slice(0, 10)).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Programs;
