import { TextField } from "@mui/material";
import style from "../../../../styles/setting.module.css";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import { FetchbankDetails } from "../../../../Api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../../redux/store";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import EditSharpIcon from "@mui/icons-material/EditSharp";

const SetTab7 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [bankdetails, setBankDetails] = useState({
    bank_name: "",
    last4: "",
    account_holder_name: "",
    routing_number: "",
  });
  const fetchBankDetails = () => {
    FetchbankDetails(cookie)
      .then((result: any) => {
        const { bank_name, last4, account_holder_name, routing_number } =
          result.data[0];
        setBankDetails({
          bank_name,
          last4,
          account_holder_name,
          routing_number,
        });
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBankDetails();
  }, []);
  return (
    <>
      <div className={style.tabcontainer}>
        <div className={style.tabheader}>Add Payment Detail</div>

        <div className={style.OutterContainer}>
          <EditSharpIcon
            style={{
              position: "absolute",
              right: "2rem",
              top: "1rem",
              cursor: "pointer",
            }}
          />
          <div className={style.Container}>
            <div className={style.BankContainer_Header}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <AccountBalanceRoundedIcon style={{ fontSize: "3rem" }} />
              </div>
              <div>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: "1.3rem",
                    lineHeight: "1.5rem",
                  }}>
                  {bankdetails.bank_name}
                </p>
                <p style={{ fontSize: "1rem", lineHeight: "1rem" }}>
                  A/c No.- {bankdetails.last4}
                </p>
              </div>
            </div>
            <div className={style.seperator} />
            <div style={{ marginTop: "0.5rem" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                {" "}
                <p>Account Holder Name:</p>
                <p>{bankdetails.account_holder_name}</p>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                {" "}
                <p>Routing Number:</p>
                <p>{bankdetails.routing_number}</p>
              </div>
            </div>
          </div>
          <div className={style.bankFooter}>
            <VerifiedRoundedIcon
              style={{ color: "#00b12c", fontSize: "2rem", margin: "0.5rem" }}
            />
            Primary bank account for transaction
          </div>
        </div>
        <div className={style.formbutton}>
          <button type="submit">Save</button>
        </div>
      </div>
    </>
  );
};

export default SetTab7;
