import { useEffect, useState } from "react";
import { ApproveCoach, fetchUsersId } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import EmailIcon from '@mui/icons-material/Email';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import style from "./usermanage.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import styles from "../../../Dashboard/Coach/all-session/session.module.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Document, Page  } from 'react-pdf';
import { notifyError, notifySuccess } from "../../../../Notify";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  coachUid: any;
};
interface DocumentLoadSuccessProps {
  numPages: number;
}
const UM3 = ({ setActiveTab, coachUid }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;

  const [data, setData] = useState<any>({
    uid:'',
    email: "",
    name: "",
    accountState: "",
    profileImg: "",
    introvideo:"",
    createdAt: "",
    location: "",
    certificationDetails: [],
    behavioralIdentity: [],
    commpower: [],
    facebook:'',
    instagram:'',
    linkedIn:'',
    phone:'',
    yearExp:'',
    topTalents:'',
    currentprofession:'',
    bankaccount:[],
    birthDate:'',
    ssNumber:''
  
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
 const [userState,setUserState]=useState(false)
 const[roleShow,setroleShow]=useState(1);
  const formattedDate = new Date(data.createdAt).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  useEffect(() => {
    const formData = new FormData();
    formData.append("uid", coachUid);
    fetchUsersId(cookie, formData)
      .then((result: any) => {
        console.log("details of userInfo", result);
        setroleShow(result.data.data.user.role)
        setData({
          uid: result.data.data.user._id,
          email: result.data.data.user.email,
          name: result.data.data.user.name,
          accountState: result.data.data.user.account_status,
          profileImg: result.data.data.user.profileImg,
          introvideo:result.data.data.userInfo.introvideo,
          createdAt: result.data.data.user.createdAt,
          location: result.data.data.userInfo.location,
          certificationDetails: result.data.data.userInfo.certificationDetails,
          behavioralIdentity: result.data.data.userInfo.behavioralIdentity,
          commpower: result.data.data.userInfo.commpower,
          facebook:result.data.data.userInfo.facebookLink,
          instagram:result.data.data.userInfo.instagramLink,
          linkedIn:result.data.data.userInfo.linkedinLink,
          phone:result.data.data.userInfo.phoneNo,
          yearExp:result.data.data.userInfo.yearExp,
          topTalents:result.data.data.userInfo.topTalents,
          currentprofession:result.data.data.userInfo.currentprofession,
          bankaccount:result.data.data.userInfo.bankaccount,
          birthDate:result.data.data.userInfo.birthDate,
          ssNumber:result.data.data.userInfo.ssNumber,
          
          
          
          
        });
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
  }, [cookie, coachUid,userState]);
const handleAccDel=(e:any,action:boolean)=>{
  e.preventDefault()
  ApproveCoach(data.uid,action,cookie)
  .then((res:any)=>{
    console.log(res)
    notifySuccess(res.data.message)
    handleClose()
  })
  .catch((err:any)=>{
    notifyError(err.response.data.message);
    handleClose()
  })

}
  return (
    <>
    {roleShow == 1 &&(<>
      <div className={styles.container}>
        <div className={style.containerTop}>
          <div className={style.topImg}>
            {data.profileImg ? (
              <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                className={style.coachImg}
              />
            ) : (
              <AccountCircleIcon className={style.coachImg} />
            )}

            <div className={style.coachName}>
              <h6>{data.name}</h6>
       
              <h6>
                <p className={style.emailIcon}>
                  <EmailIcon /> {data.email}
                </p>
              </h6>
              <h6>
                <p className={style.emailIcon}>
                  <AccessTimeFilledIcon /> {formattedDate}
                </p>

              </h6>
              <h6>
                <p className={style.emailIcon}>
                  <LocalPhoneIcon/> {data.phone} </p>
              </h6>
            </div>
          </div>
          <div className={style.topTotalSect}>
            <div className={style.totalsec}>
              <span>Total Session</span>
              <h5>43</h5>
            </div>
            <div className={style.totalsec}>
              <span>Session Completed</span>
              <h5>34</h5>
            </div>
            <div className={style.totalsec}>
              <span>Total Earnings</span>
              <h5>34</h5>
            </div>
          </div>
          <div className={style.topDisp}>
          <div className={style.topBtn}>
            <button className={styles.topBtn3} onClick={handleOpen}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>
            <button className={style.topBtn1}>
              <DeleteIcon className={style.topBtn2} /> Delete
            </button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={style.profile}>
                    {data.profileImg ? (
                      <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                        className={style.profileImg}
                      />
                    ) : (
                      <AccountCircleIcon className={style.profileImg} />
                    )}
                    <h3> Details of {data.name}</h3>
                  </div>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                  <div className={style.ProgramProp}>
                  <hr />
                    <div >
                      
                      <div className={style.onlinePro}>
                      <h6>Common Power:<p>{data.commpower}</p></h6>
                      </div>
                      <div className={style.onlinePro}>
                      <h6>Location:<p>{data.location}</p></h6>
                      
                        <h6>Behaviour Identity<p>{data.behavioralIdentity}</p></h6>
                      </div>
                    <div className={style.onlinePro}>
                      <p><FacebookIcon  className={style.onlineProic1}/> {data.facebook}</p>
                      <p><InstagramIcon className={style.onlineProic2}/>{data.instagram}</p>
                      <p><LinkedInIcon className={style.onlineProic1}/>{data.linkedIn}</p>
                    </div>
                    <div className={style.onlineProBtn}>
                    <button className={styles.topBtn3} onClick={(e:any)=>{handleAccDel(e,true)}}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>
            <button className={style.topBtn1} onClick={(e:any)=>{handleAccDel(e,false)}}>
              <DeleteIcon className={style.topBtn2} /> Delete
            </button>
                    </div>
                    </div>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
          <div className={style.topDispIntroVideo}>
         
          <video src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.introvideo}`} width="200" height="100" controls />
          </div>
          
          </div>
        </div>
        
<div className={styles.showDetailsCoach}>
<div className={styles.showDetails}>
  <p>Years of Experience</p>
  <h6>{data.yearExp}</h6>
</div>
<div className={styles.showDetails}>
  <p>Behavioral Superpowers Identity</p>
  <h6>{data.behavioralIdentity}</h6>
</div>
<div className={styles.showDetails}>
  <p>Top Talents</p>
  <h6>{data.topTalents}</h6>
</div>
<div className={styles.showDetails}>
  <p>location</p>
  <h6>{data.location}</h6>
</div>

</div>
<hr/>
<div className={styles.showDetailsCoach}>

<div className={styles.showDetails}>
  <p>Communication SuperPower</p>
  <h6>{data.commpower}</h6>
</div>
<div className={styles.showDetails}>
  <p>Current Profession</p>
  <h6>{data.currentprofession}</h6>
</div>
<div className={styles.showDetails}>
  <p>BirthDate</p>
  <h6>{data.birthDate}</h6>
</div>
<div className={styles.showDetails}>
  <p>SS Number</p>
  <h6>{data.ssNumber}</h6>
</div>
</div>
<hr/>
<div className={styles.showDetailsCoach}>
<div className={styles.showDetails}>
  <p>Bank Name</p>
  <h6>{data.bankaccount.bankName}</h6>
</div>  
<div className={styles.showDetails}>
  <p>Bank A/c number</p>
  <h6>{data.bankaccount.accNo}</h6>
</div>
<div className={styles.showDetails}>
  <p>Routing Number</p>
  <h6>{data.bankaccount.routingNo}</h6>
</div>
<div className={styles.showDetails}>
  <p>Bank A/c Type </p>
  <h6>{data.bankaccount.accType}</h6>
</div>

</div>
<hr/>
<div className={styles.showCertificates}>
  {data.certificationDetails.length>0?(data.certificationDetails.map((certi:any,index:number)=>(<>
  <div className={styles.certicontent}>
 {/* <div> 
  <p>coach School </p>
  <h6>{certi.coachSchool}</h6>
  </div>
  <div> 
  <p>coach speciality </p>
  <h6>{certi.speciality}</h6>
  </div> */}
<h5 onClick={handleOpen1}>{index+1}. View Certificate</h5>

<Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={style.profile}>
                    {data.profileImg ? (
                      <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                        className={style.profileImg}
                      />
                    ) : (
                      <AccountCircleIcon className={style.profileImg} />
                    )}
                    <h3> Certificates  of {data.name}</h3>
                  </div>
                </Typography>
                <Typography   id="modal-modal-description"
                  className={style.typoDesc}>

                </Typography>
                </Box>
                </Modal>
 
  
</div>


  </>))):(<>No Any Certicate Added!</>)}
</div>

        <div className={styles.upcomeAppContent}>
          <ul>
            <li>Upcoming Appointment</li>
            <li>Completed Appointment</li>
            <li>Group Engagement</li>
          </ul>
        </div>
       
        <div>
    
    </div>

      </div>
    </>)}
    {
      roleShow ==2 &&(<>
        <div className={styles.container}>
        <div className={style.containerTop}>
          <div className={style.topImg}>
            {data.profileImg ? (
              <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                className={style.coachImg}
              />
            ) : (
              <AccountCircleIcon className={style.coachImg} />
            )}

            <div className={style.coachName}>
              <h6>{data.name}</h6>
       
              <h6>
                <p className={style.emailIcon}>
                  <EmailIcon /> {data.email}
                </p>
              </h6>
              <h6>
                <p className={style.emailIcon}>
                  <AccessTimeFilledIcon /> {formattedDate}
                </p>

              </h6>
              {/* <h6>
                <p className={style.emailIcon}>
                  <LocalPhoneIcon/> {data.phone} </p>
              </h6> */}
            </div>
          </div>
          <div className={style.topTotalSect}>
            <div className={style.totalsec}>
              <span>Total Session</span>
              <h5>43</h5>
            </div>
            <div className={style.totalsec}>
              <span>Session Completed</span>
              <h5>34</h5>
            </div>
            {/* <div className={style.totalsec}>
              <span>Total Earnings</span>
              <h5>34</h5>
            </div> */}
          </div>
          <div className={style.topDisp}>
          <div className={style.topBtn}>
            {/* <button className={styles.topBtn3} onClick={handleOpen}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>
            <button className={style.topBtn1}>
              <DeleteIcon className={style.topBtn2} /> Delete
            </button> */}

          
          </div>
          
          
          </div>
        </div>
 

        <div className={styles.upcomeAppContent}>
          <ul>
            <li>Upcoming Appointment</li>
            <li>Completed Appointment</li>
            <li>Group Engagement</li>
          </ul>
        </div>
       
        <div>
    
    </div>

      </div>
    </>)}
   
     
    </>
    
  );
}

export default UM3;
