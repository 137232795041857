import { useEffect, useState } from "react";
import { CreateSessionByCoachee, fetchCard, fetchCoachByCoachee, fetchCoachDataForCoachee, fetchCoachprogramByID } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError, notifySuccess } from "../../../../Notify";
import EmailIcon from '@mui/icons-material/Email';
import styles from "../CSS/dashboardHome.module.css";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Box, Button, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddCardIcon from '@mui/icons-material/AddCard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { PaymentModal } from "../../../modal/PaymentModal";
import dayjs from "dayjs";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  coachId: any;

};
const ShowCoach = ({ setActiveTab, coachId }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [comPow, setcomPow] = useState<string[]>([])
  const [cardId, setCardId] = useState("")
  const [coachdata, setCoachData] = useState()
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<any[]>([]);
  const [Typo, setTypo] = useState(0)
  const[programData,setProgramData]=useState<any[]>([])

  const [appDetails, setAppDetails] = useState({
    "coachId": "",
    "pId": "",
    "gId": "",
    "starttime": "",
    "endtime": "",
    "appType": "",
    "date": "",
  })
  const [data, setData] = useState<any>(
    //   {
    //   name: "",
    //   email: "",
    //   profile: "",
    //   timeZone: "",
    //   avail: [],
    // }
  )
useEffect(()=>{
  fetchCoachprogramByID(coachId,cookie)
  .then((res:any)=>{

    console.log("data of program By coach",res.data)
    setProgramData(res.data.history)
  })
},[cookie])
  const handleOpen = () => setOpen1(true);
  // const handleClose = () => ;
  useEffect(() => {
    fetchCard(cookie)
      .then((result: any) => {
        setCards(result.data)
      })
      .catch((error: any) => {
        console.log("from Card", error)
      })
  }, [cookie])

  const handleCloseModal = () => {
    setOpen1(false)
    setTypo(0)
  }

  const handleCardVal = (event: any) => {
    setCardId(event.target.value)
  }

  useEffect(() => {
    fetchCoachDataForCoachee(cookie, coachId)
      .then((result: any) => {
        setData(
          // name: result.data.coach.uid.name,
          // email: result.data.coach.uid.email,
          // profile: result.data.coach.uid.profileImg,
          // timeZone: result.data.coach.timeZone,
          // avail: result.data.availability,
          result.data
        )
        setcomPow(result.data.coach.commpower)
        setLoading(false)
      })


      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error);
      });
  }, [cookie, coachId])
  // CreateSessionByCoachee
  const appType = 1;




  // useEffect(() => {
  //   fetchCoachByCoachee(cookie)
  //     .then((result: any) => {
  //       // setMon(result.data.ava)
  //       // seCoachData(result.data.history)


  //     })
  //     .catch((error: any) => {
  //       console.error("Error fetching coach data:", error);
  //       notifyError(error);
  //     });
  // }, [cookie]);

  const handleSubmit = (e: any) => {
    e.preventDefault()
if (!appDetails.pId ){
  notifyError("Select One Program!")
}
else{
  CreateSessionByCoachee(coachId, appDetails.starttime,appDetails.endtime, appType, appDetails.date, appDetails.pId, cookie)
  .then((result) => {
    notifySuccess(`Congrates! Your session is planned !`)
    handleCloseModal()
    
  }).catch((err: any) => {
    notifyError(err.response.data.message)
    handleCloseModal()
    setTypo(0)
  })
}
   

  }

  return (
    !loading ? (<div className={styles.dashcontainer}>
      <div className={styles.UpcomingContainer}>


        <div className={styles.recommendContainer}>
          <div className={styles.recommendLeft}>
            {data?.coach.uid.profileImg ? (<img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${data?.coach.uid.profileImg}`}
              className={styles.coachImg}
            />) : (
              <h4 className={styles.coachIcon}>{data?.coach.name?.slice(0, 2).toUpperCase()}</h4>
            )}

            <span>
              <h5>{data.coach.uid.name || 'User'}</h5>
              <p className={styles.dataIcoCoach}><span><EmailIcon /></span> {data.coach.uid.email}</p>
            </span>
          </div>
          <div className={styles.recommendRight}>
            <button className={styles.TypoButton} onClick={handleOpen}><span><EditCalendarIcon /></span>Schedule Appointment</button>

            <Modal
              open={open1}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleCloseModal} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                <h5> Schedule Appointment</h5>

                </Typography>

                <PaymentModal data={data} coachId={coachId} comPow={comPow} setTypo={setTypo}  setAppDetails={setAppDetails} programData={programData}/>
                <button onClick={(e:any) =>handleSubmit(e)} className={styles.TypoButton}>Save</button>

              


              </Box>
            </Modal>
          </div>

        </div>

        <hr />
        <div>
          <b>About Coach</b>

          <p className={styles.dataColors}>
            Lorem Ipsum is simply dummy text of the printing and type setting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the
            1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
            recently with desktop publishing software like Aldus PageMaker including versions of
            Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and type setting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>
        </div>

        <div>
          <br />
    
       <b>Categories that can help</b>
          <b>{comPow.length > 0 ? (comPow.map((compow: any) => (<>
            
            <p className={styles.comPowStyle}>
              {compow.split(',').map((part: string, i: number) => (
                <p key={i} className={styles.comPowStyles}>
                  {part.trim()}
                </p>
                
              ))}
            </p>
          </>))) : (<>No Data found!</>)}</b>
          <br/>
          <h6><b>Entitled With Programs</b></h6>
       <div className={styles.DashForRecom}>
       
       <div className={styles.Recommond}>
          {programData.length>0?(programData.map((program:any)=>(

            <>
               <div className={styles.coaching}>
               <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${program.programDetails?.programImg}`}
                        className={styles.coachingImg}
                      />
                      <div className={styles.coachingTxt} >
                      <p>{program.programDetails?.name}</p>
                      <span>{program.programDetails?.description.slice(0,60)}..</span>
                      </div>
               </div>
            </>
          ))):(<>Please Select Programs</>)}

</div>
</div>
          
        </div>
        <div>
  
        </div>

      </div>

    </div>) : <></>
  )

}
export default ShowCoach;