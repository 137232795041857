import React, { useState } from "react";
import Home from "../Home";
import UpcomingCoaching from "../UpcomingCoaching";
import MyCoach from "../MyCoach";
import Recommend from "../Recommend";
import MyReport from "../MyReport";

import Settings from "../Settings";
import Assessment from "../Assessment";
import MyCertificate from "../MyCertificate";
import Wrapper from "../../wrapper/Wrapper";
import Support from "../../Coach/support";
import ProfileHome from "../Profile/ProfileHome";
import Programs from "../Programs";
import MyGroups from "../MyGroups";
import ChatCoach from "../ChatCoach/ChatCoach";
const Index = () => {
  const [activeTab, setActiveTab] = useState(1);
  const[subActiveTab,setSubActiveTab]=useState(0)
  return (
    <Wrapper activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === 1 && <Home setActiveTab={setActiveTab}/>}
      {activeTab === 2 && <UpcomingCoaching />}
      {activeTab === 3 && <MyCoach />}
      {activeTab === 4 && <Recommend setSubActiveTab={setSubActiveTab}/>}
      {activeTab === 5 && <MyReport />}
      {activeTab === 6 && <Programs />}
      {activeTab === 7 && <Settings subActiveTab={subActiveTab}/>}
      {activeTab === 8 && <Assessment />}
      {activeTab === 9 && <MyCertificate />}
      {activeTab === 10 && <Support />}
      {activeTab === 11 && <ProfileHome setActiveTab={setActiveTab} />}
      {activeTab === 12 && <MyGroups/>}
      {activeTab === 13 && <ChatCoach/>}
    </Wrapper>
  );
};

export default Index;
