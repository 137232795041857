import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import styles from "./css/AdminManage.module.css"
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "./wrapper/wrapper.module.css"
import style from "./programs/programs.module.css";
import EmailIcon from '@mui/icons-material/Email';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { Box, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchAdmin } from "../../../Api";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
interface NewUser {
  pImg: File | null;
}
const AdminManagement=()=>{
     const user = useSelector((state: RootState) => state.user.user);
     const [adminData,setAdminData]=useState<any[]>([])
     const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [previewImage, setPreviewImage] = useState("");
    const [newUser, setNewUser] = useState<NewUser>({
      pImg: null,
    });
    const handleProfileClick = () => {
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.click();
      }
    }; 
    const handleProfile = (e: any) => {
      const file = e.target.files[0];
      setNewUser({ ...newUser, pImg: file });
      console.log("files", file);
  
      const imageURL = URL.createObjectURL(file);
      setPreviewImage(imageURL);
    };
  
  const cookie: any = user?.token;
    useEffect(()=>{
        fetchAdmin(cookie,1)
        .then((res:any)=>{
            console.log(res);
            setAdminData(res.data.history)

            
        })

    },[cookie])
    const createNewAdmin=(e:any)=>{
      e.preventDefault();
    }
  
    return(<>
    <div className={styles.AdminManage}>
    <div className={styles.AdminManageHead}>
    <h5>Admin Management</h5>
    <button onClick={handleOpen}><AddIcon/>Add New Admin</button>
    </div>
    <div className={styled.upgradeButton}>
            
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
            >
              <Box className={styled.BoxStyle}>
                <span onClick={handleClose} className={styled.HandleClose}>
                  <CloseTwoToneIcon className={styled.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={styled.thisData}>
                  <h5> Create New Admin</h5>
                  
                  </div>
               
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={styled.typoDesc}
                >
                   <form className={styled.programForm} >
                   <div className={styled.ProgramProp}>
                   
                   <div className={styled.onlinePro} >
                 
                    <div className={styled.profileImg}>
                    <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
                onChange={handleProfile}
              id="fileInput"
              name="profile"
            />
                    {!previewImage ? (
              <>

                <img
                  src="/images/Admin/unnamed.jpg"
                  className={styled.progProfile}
                />
                <div className={styled.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
              </>
            ) : (
              <>
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styled.MainImgPre}
                />
                <div className={styled.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
              </>
            )}
            </div>
            </div>
                    </div>
                    <div className={styles.inputTxtContent}>
                    <TextField
          type="text"
          id="outlined-basic"
          label="First Name"
          variant="outlined"
          name="firstname"
          // value={question}
          // onChange={(e: any) => setQuestion(e.target.value)}
          className={styles.Inputfield}
          InputProps={{
            className: `${styled.muiInput}`,
            
          }}
          sx={{
            my: 1,
            "& .MuiInputBase-input": {
              width: "100%",
              
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
  },
          }}
        />
        <TextField
          type="text"
          id="outlined-basic"
          label="Last Name"
          variant="outlined"
          name="lastname"
          // value={question}
          // onChange={(e: any) => setQuestion(e.target.value)}
          className={styles.Inputfield}
          InputProps={{
            className: `${styled.muiInput}`,
            
          }}
          sx={{
            my: 1,
            "& .MuiInputBase-input": {
              width: "100%",
              
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
  },
          }}
        />
                    </div>
                  
                    <div className={styles.inputTxtContent}>
                    <TextField
          type="text"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          name="email"
          // value={question}
          // onChange={(e: any) => setQuestion(e.target.value)}
          className={styles.Inputfield}
          InputProps={{
            className: `${styled.muiInput}`,
            
          }}
          sx={{
            my: 1,
            "& .MuiInputBase-input": {
              width: "100%",
              
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
  },
          }}
        />
        <TextField
          type="text"
          id="outlined-basic"
          label="Phone No"
          variant="outlined"
          name="phone"
          // value={question}
          // onChange={(e: any) => setQuestion(e.target.value)}
          className={styles.Inputfield}
          InputProps={{
            className: `${styled.muiInput}`,
            
          }}
          sx={{
            my: 1,
            "& .MuiInputBase-input": {
              width: "100%",
              
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
  },
          }}
        />
                    </div>
        <br/>
         <button  className={styled.SaveButton} >
            Save
          </button>
                  </form>
               
                  
                
                </Typography>
              </Box>
            </Modal>
          </div>
       
    <div className={styles.AdminManageDisp}>
        
    <div className={style.programContent}>
        {adminData.length > 0 ? (
          adminData.map((admin: any, index: number) => (
            <> 
 <div className={style.DisplayProg}>
                <div className={style.progContent} >
                    {admin.profileImg?( <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${admin.profileImg}`}
                  />):( <img
                    src="./images/Coachee/p6.png"
                  />)}
                 
                 
                  <div className={style.programText}>
                    <div className={styles.adminData}>
                    <h6 >{admin.name.length>20?(<>{admin.name.slice(0,19)}...</>):(admin.name)}</h6>
                    <DeleteIcon className={styles.adminDataIcon} onClick={()=>setOpen(true)}/>
                    </div>
                    <p className={styles.adminDataclr}><EmailIcon/> {admin.email.length>20?(<>{admin.email.slice(0,19)}...</>):(admin.email)}</p>
                    <p className={styles.adminDatacl}><CheckCircleIcon/> {dayjs(admin.createdAt.slice(0,10)).format('DD MMM YYYY')}</p>
                   
                    
                 
                  </div>
                 
                </div>
              </div>
             
            </>
          ))
        ) : (
        <></>
        )}
      </div>


    </div>
         </div>
    </>)
}
export default AdminManagement;