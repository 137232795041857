import { useState } from "react";
import style from "./landing.module.css";
import Home from "../Home";
import AboutUs from "../AboutUs";
import BehaveGame from "../BehaveGame";
import Services from "../Services";
import Blogs from "../Blogs";
import ContactUs from "../ContactUs";
import { useNavigate } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { TextField } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TermsCondi from "../TermsCondi";
import PrivPoli from "../PrivPoli";
import CardSlider from "../Model/CardSlider";

const LandingWrapper = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const handleClick = () => {
    navigate("/signup");
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.bkTop}>
          <img
            src="/images/svg/Coach/shapebk.svg"
            className={style.bkTopInner}
            alt=""
          />
        </div>
        <div className={style.foreGround}>
          <>
            <div className={style.navbar}>
              <ul>
                <li>
                  <img
                    src="/images/Coach/login/white-logo.png"
                    className={style.navbarImg}
                    alt=""
                  />
                </li>
                <li className={style.TopNavContent}>
                  <ul>
                    <li
                      onClick={() => setActiveTab(1)}
                      className={` ${activeTab === 1 && style.tabActive1}`}>
                      Home
                    </li>
                    <li
                      onClick={() => setActiveTab(2)}
                      className={` ${activeTab === 2 && style.tabActive1}`}>
                      About Us
                    </li>
                    {/* <li
                    onClick={() => setActiveTab(3)}
                    className={` ${activeTab === 3 && style.tabActive1}`}
                  >
                    Behavioural Games
                  </li> */}
                    <li
                      onClick={() => setActiveTab(4)}
                      className={` ${activeTab === 4 && style.tabActive1}`}>
                      Services
                    </li>
                    <li
                      onClick={() => setActiveTab(5)}
                      className={` ${activeTab === 5 && style.tabActive1}`}>
                      Blogs
                    </li>
                    <li
                      onClick={() => setActiveTab(6)}
                      className={` ${activeTab === 6 && style.tabActive1}`}>
                      Contact Us
                    </li>
                  </ul>
                </li>
                <li>
                  <button className={style.letsBtn} onClick={handleClick}>
                    Let's get started!
                  </button>
                </li>
              </ul>
            </div>
          </>
          {activeTab === 1 && <Home setActiveTab={setActiveTab} />}
          {activeTab === 2 && <AboutUs setActiveTab={setActiveTab} />}
          {activeTab === 3 && <BehaveGame setActiveTab={setActiveTab} />}
          {activeTab === 4 && <Services setActiveTab={setActiveTab} />}
          {activeTab === 5 && <Blogs setActiveTab={setActiveTab} />}
          {activeTab === 6 && <ContactUs setActiveTab={setActiveTab} />}
          {activeTab === 7 && <TermsCondi setActiveTab={setActiveTab} />}
          {activeTab === 8 && <PrivPoli setActiveTab={setActiveTab} />}
        </div>
        
      </div>
      <footer className={style.footer}>
        <div className={style.footerLogo}>
          <img src="/images/Coach/login/white-logo.png" alt="" />
        </div>
        <div className={style.footerIcons}>
          <div className={style.footerIconsContent}>
            <div className={style.LeftfooterIcons}>
              <h1>We help you to grow, so get started</h1>
              <p>
                <PinDropIcon className={style.LeftImgIconFoot} />
                4517 Washington Ave. Manchester, Kentucky 39495
              </p>
              <p>
                <LocalPhoneIcon className={style.LeftImgIconFoot} />
                12345 67890
              </p>
            </div>
            <div className={style.RightfooterIcons}>
              <div className={style.RightfooterContent}>
                <TextField
                  // id="full-width-text-field"
                  type="email"
                  label="Enter Your Email "
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input": {
                      width: "100%",
                    },
                    "& .MuiInputLabel-root": {
                      backgroundColor: "#fff",
                    },
                  }}
                  // variant="standard"
                  className={style.Rightfooterinput}
                />
                <button className={style.RightfooterBtn}>Subscribe</button>
              </div>
              <div className={style.Rightfootertabs}>
              <div className={style.RightfootertabsContent}>
                <p onClick={() => setActiveTab(1)}> Home </p>
                <p onClick={() => setActiveTab(2)}>About Us </p>
                {/* <p onClick={() => setActiveTab(3)}>Behavioral Games</p> */}
                <p onClick={() => setActiveTab(4)}>Services</p>
                </div>
                <div className={style.RightfootertabsContent}>
                <p onClick={() => setActiveTab(5)}>Blogs</p>
                <p onClick={() => setActiveTab(6)}>Contact Us</p>
                </div>
              </div>
              <div className={style.Rightfootertabsb}>
                <p onClick={() => setActiveTab(7)}>Terms & Conditions</p>
                <p onClick={() => setActiveTab(8)}>Privacy Policy</p>
              </div>
              <div className={style.RightfooterIconsb}>
                <FacebookIcon className={style.RightfooterIconsU} />
                <InstagramIcon className={style.RightfooterIconsU} />
                <TwitterIcon className={style.RightfooterIconsU} />
                <YouTubeIcon className={style.RightfooterIconsU} />
              </div>
            </div>
          </div>
          <div className={style.footercopyRight}>
            {" "}
            <p>© Copyright 2024, All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingWrapper;
