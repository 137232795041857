import { useEffect, useState } from "react";
import CpTab1 from "../components/complete-profile/CP-Tab1";
import CpTab2 from "../components/complete-profile/CP-Tab2";
import CpTab3 from "../components/complete-profile/CP-Tab3";
import CpTab4 from "../components/complete-profile/CP-Tab4";
import AuthWrapper from "../components/layouts/authWrapper";
import Style from "../styles/setup.module.css";
import SetupComplete from "../components/complete-profile/SetupComplete";
import CoacheeTab1 from "../components/complete-profile/Coachee/CP-Tab1";
import Coacheetab2 from "../components/complete-profile/Coachee/CP-Tab2";
import SetupComplete1 from "../components/complete-profile/Coachee/SetupComplete";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import CpTab5 from "../components/complete-profile/CP-Tab5";
import AuthenPending from "../components/layouts/AuthenPending";
import AuthenticatedLayout from "../components/layouts/AuthenticatedLayout";
const Setup = ({}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [cactiveTab, setCActiveTab] = useState(1);
  const user: any = useSelector((state: RootState) => state.user.user);
  const [ProfileCount, setProfileCount] = useState(user?.profileCount);

  const InitialCheck = () => {
    if (!ProfileCount) {
      setActiveTab(1);
    } else if (ProfileCount === 0) {
      setActiveTab(1);
    } else {
      setActiveTab(ProfileCount + 1);
    }
  };

  useEffect(() => {
    InitialCheck();
  }, []);

  return (
    <>
      <AuthenticatedLayout>
        {user?.role === 1 && (
          <>
            {" "}
            <AuthWrapper
              heading="Complete your profile"
              message="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
              {/* Tab Container */}
              <div className={Style.TabContainer}>
                {activeTab < 6 && (
                  <ul className={Style.tabCnt1}>
                    <li
                      className={`${Style.tab} ${
                        activeTab === 1 && Style.tabActive
                      }`}
                      onClick={() => setActiveTab(1)}>
                      Personal Information
                    </li>

                    <li
                      className={`${Style.tab} ${
                        activeTab === 2 && Style.tabActive
                      }`}
                      onClick={() => setActiveTab(2)}>
                      Coach And Related Certifications
                    </li>

                    <li
                      className={`${Style.tab} ${
                        activeTab === 3 && Style.tabActive
                      }`}
                      onClick={() => setActiveTab(3)}>
                      Coaching Experience
                    </li>

                    <li
                      className={`${Style.tab} ${
                        activeTab === 4 && Style.tabActive
                      }`}
                      onClick={() => setActiveTab(4)}>
                      Administration
                    </li>
                    <li
                      className={`${Style.tab} ${
                        activeTab === 5 && Style.tabActive
                      }`}
                      onClick={() => setActiveTab(5)}>
                      Bank Details
                    </li>
                  </ul>
                )}
              </div>
              {user?.account_status === 1 && user?.profileSetup === false && (
                <>
                  {activeTab === 1 && <CpTab1 setActiveTab={setActiveTab} />}
                  {activeTab === 2 && <CpTab2 setActiveTab={setActiveTab} />}
                  {activeTab === 3 && <CpTab3 setActiveTab={setActiveTab} />}
                  {activeTab === 4 && <CpTab4 setActiveTab={setActiveTab} />}
                  {activeTab === 5 && <CpTab5 setActiveTab={setActiveTab} />}
                  {activeTab === 6 && <SetupComplete />}
                </>
              )}
              {user?.account_status === 1 && user?.profileSetup === true && (
                <>
                  {activeTab === 6 && <SetupComplete />}
                  {activeTab !== 6 && setActiveTab(6)}
                </>
              )}
            </AuthWrapper>
          </>
        )}
        {/* 250,420 */}
        {user?.role === 2 && (
          <>
            {" "}
            <AuthWrapper
              heading="Complete your profile"
              message="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
              {/* Tab Container */}
              {cactiveTab < 3 && (
                <ul className={Style.tabCnt1}>
                  <li
                    className={`${Style.tab} ${
                      cactiveTab === 1 && Style.tabActive
                    }`}
                    onClick={() => setCActiveTab(1)}>
                    Personal Information
                  </li>

                  <li
                    className={`${Style.tab} ${
                      cactiveTab === 2 && Style.tabActive
                    }`}
                    onClick={() => setCActiveTab(2)}>
                    Couch Quality Like
                  </li>
                </ul>
              )}

              {cactiveTab === 1 && (
                <CoacheeTab1 setCActiveTab={setCActiveTab} />
              )}
              {cactiveTab === 2 && (
                <Coacheetab2 setCActiveTab={setCActiveTab} />
              )}
              {cactiveTab === 3 && <SetupComplete1 />}
            </AuthWrapper>
          </>
        )}
      </AuthenticatedLayout>
    </>
  );
};

export default Setup;
