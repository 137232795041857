import { useEffect, useState } from "react";
import style from "../../../../styles/setting.module.css";
import { TextField } from "@mui/material";
import { FetchCoachInfo } from "../../../../Api";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import { cdn_Link } from "../../../../config";

const SetTab2 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [data, setData] = useState({
    certificationDetails: [],
    certified: false,
    ethics: false,
  });

  const [multiForms, setMultiForms] = useState<any[]>([
    {
      coachSchool: "",
      speciality: "",
      othercerti: "",
      certificateImg: null,
    },
  ]);

  const fetchCoachDetails = () => {
    FetchCoachInfo(cookie)
      .then((result: any) => {
        const { certificationDetails, certified, ethics }: any = result.data;
        setData({ certificationDetails, certified, ethics });
      })
      .catch((err: any) => console.log(err));
  };

  const deleteForm = (indexToDelete: any) => {
    setMultiForms((prevForms: any) =>
      prevForms.filter((multiForms: any, index: any) => index !== indexToDelete)
    );
  };

  const addForm = () => {
    setMultiForms([
      ...multiForms,
      {
        coachSchool: "",
        speciality: "",
        othercerti: "",
        certificateImg: null,
      },
    ]);
  };

  useEffect(() => {
    fetchCoachDetails();
  }, []);

  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>
          Coach and Related Certification
        </header>
        <div className={style.tabformcontent}>
          <div className={style.leftabcontent}>
            <header>Coaching Certificate</header>
            <div className={style.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={data.certified}
                  onChange={() =>
                    setData({ ...data, certified: !data.certified })
                  }
                  className={style.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={!data.certified}
                  onChange={() =>
                    setData({ ...data, certified: !data.certified })
                  }
                  className={style.YesNo}
                />
                No
              </label>
            </div>
          </div>
          <div className={style.rightabcontent}>
            <header>Understanding of coaching ethics</header>
            <div className={style.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={data.ethics}
                  onChange={() => setData({ ...data, ethics: !data.ethics })}
                  className={style.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={!data.ethics}
                  onChange={() => setData({ ...data, ethics: !data.ethics })}
                  className={style.YesNo}
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "8px", padding: "4px" }}>
          {data.certificationDetails.length > 0 &&
            data.certificationDetails.map((elem: any, index: number) => (
              <div>
                <img
                  src={
                    `${cdn_Link}/${elem?.certificateImg}` ||
                    "/images/Coachee/p1.png"
                  }
                  alt=""
                  style={{ height: "8rem", width: "8rem" }}
                />
              </div>
            ))}
        </div>
        <div>
          {true && (
            <>
              {multiForms.map((form, index) => (
                <div key={index} className={style.InputDataContainer}>
                  {index > 0 && (
                    <DeleteForeverIcon
                      onClick={() => deleteForm(index)}
                      className={style.multiFmIcon}
                    />
                  )}
                  <div className={style.leftInputData}>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Coach Training School"
                      variant="outlined"
                      name="coachSchool"
                      value={form.coachSchool}
                      onChange={(e) => {
                        const newForms = [...multiForms];
                        newForms[index].coachSchool = e.target.value;
                        setMultiForms(newForms);
                      }}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${style.muiInput}`,
                      }}
                      sx={{
                        mr: 1,
                        my: 1,
                        borderRadius: "20px",
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                      }}
                    />
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Specialty Experience/Sweet Spot"
                      variant="outlined"
                      name="special"
                      value={form.speciality}
                      onChange={(e) => {
                        const newForms = [...multiForms];
                        newForms[index].speciality = e.target.value;
                        setMultiForms(newForms);
                      }}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${style.muiInput}`,
                      }}
                      sx={{
                        mr: 1,
                        my: 1,
                        borderRadius: "20px",
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                      }}
                    />
                  </div>
                  <div className={style.RightInputData}>
                    {/* <TextField
                        type="text"
                        id="outlined-basic"
                        label="Other Certifications"
                        variant="outlined"
                        name="othercerti"
                        value={form.othercerti}
                        onChange={(e) => {
                          const newForms = [...multiForms];
                          newForms[index].othercerti = e.target.value;
                          setMultiForms(newForms);
                        }}
                        className={style.Inputfield}
                        InputProps={{
                          className: `${style.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                      /> */}
                    <TextField
                      type="file"
                      name="certificateImg"
                      onChange={(e: any) => {
                        const newForms = [...multiForms];
                        newForms[index].certificateImg = e.target.files[0];
                        setMultiForms(newForms);
                      }}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${style.muiInput}`,
                      }}
                      sx={{
                        mr: 1,
                        my: 1,
                        borderRadius: "20px",
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                      }}
                    />
                  </div>
                </div>
              ))}
              <div className={style.Addbtn}>
                <AddIcon className={style.AddbtnContainer} onClick={addForm} />
              </div>
            </>
          )}
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab2;
