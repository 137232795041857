import React, { useEffect, useState } from "react";
import Style from "../../../styles/session.module.css";
import AS1 from "./AllSessions/AS1";
import AS2 from "./AllSessions/AS2";
import AS3 from "./AllSessions/AS3";
import { FetchSessionAdmin } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import dayjs from "dayjs";

const Session = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchSession = () => {
    FetchSessionAdmin({ cookie, filter: activeTab })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setData(x);
      })
      .catch((err) => console.log(err));
  };

  const MappedData: any = {
    1: "Program Session",
    2: "Group Session",
  };
  useEffect(() => {
    fetchSession();
  }, [activeTab]);
  return (
    <div className={Style.Artist}>
      <ul className={Style.tabCnt1}>
        <li
          className={`${Style.tab1} ${activeTab === 1 && Style.tabActive1}`}
          onClick={() => setActiveTab(1)}>
          All Sessions<p> </p>
        </li>
        <li
          className={`${Style.tab1} ${activeTab === 2 && Style.tabActive1}`}
          onClick={() => setActiveTab(2)}>
          Upcoming Sessions<p> </p>
        </li>
        <li
          className={`${Style.tab1} ${activeTab === 3 && Style.tabActive1}`}
          onClick={() => setActiveTab(3)}>
          Completed Sessions<p> </p>
        </li>
      </ul>
      <div className={Style.maindiv} style={{ overflowX: "auto" }}>
        <table className={Style.curvedbordertable}>
          <tr>
            <th>Session Title </th>
            <th>Session Type</th>
            <th>Coach Name</th>
            <th>Coachee Name</th>
            <th>Session Date </th>
            <th>Session Timings </th>
          </tr>

          {data.history.length > 0 &&
            data.history.map((elem: any, index: number) => (
              <tr
                className={Style.tableTr1}
                key={`${elem?._id}_Sessions_${index}`}>
                <td>{elem?.programData?.name}</td>
                <td>{MappedData[elem?.AppType]}</td>
                <td>{elem?.coachData?.name}</td>
                <td>{elem?.coacheeData?.name}</td>
                <td>
                  {dayjs(elem?.startdate).format("DD MMM ")} -{" "}
                  {dayjs(elem?.enddate).format("DD MMM YYYY")}
                </td>
                <td>
                  {dayjs(elem?.startdate).format("HH:mmA")} -{" "}
                  {dayjs(elem?.enddate).format("HH:mm A")} ({elem?.duration}{" "}
                  Min)
                </td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  );
};

export default Session;
