import React, { useState } from 'react';
import style from "./cardSlider.module.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
interface Card {
    id: number;
    title: string;
    description: string;
    img: any;
    desg: string;
}

const cards: Card[] = [
    { id: 1, title: 'John Doe', desg: 'CEO, Company name', img: './images/Coachee/p6.png', description: 'Lorem Ipsum is simply dummy text of the printing and type setting industry has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to on people goes under takern goes on ' },
    { id: 2, title: 'Willson Down', desg: 'CEO, Company name', img: './images/Coachee/p2.png', description: 'Lorem Ipsum is simply dummy text of the printing and type setting industry has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to on people goes under takern goes on ' },
    { id: 3, title: 'Alina Henry', desg: 'CEO, Company name', img: './images/Coachee/p3.png', description: 'Lorem Ipsum is simply dummy text of the printing and type setting industry has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to on people goes under takern goes on ' },
    { id: 4, title: 'Chistin Matt', desg: 'CEO, Company name', img: './images/Coachee/p4.png', description: 'Lorem Ipsum is simply dummy text of the printing and type setting industry has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to on people goes under takern goes on ' },
    { id: 5, title: 'Brooklyn Sam', desg: 'CEO, Company name', img: './images/Coachee/p5.png', description: 'Lorem Ipsum is simply dummy text of the printing and type setting industry has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to on people goes under takern goes on ' },
];

const CardSlider = () => {
    const [expandedCardIndex, setExpandedCardIndex] = useState<any>(0);
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate("/signup");
      };
    const handleCardClick = (index: number) => {
        
        setExpandedCardIndex(index);
    };

    const nextCard = () => {
        if (expandedCardIndex < cards.length - 1) {
            
            setExpandedCardIndex(expandedCardIndex + 1);
        }
    };

    const prevCard = () => {
        if (expandedCardIndex > 0) {
            setExpandedCardIndex(expandedCardIndex - 1);
        }
    };

    return (
        <div className={style.CardSliderContent}>
        <div className={style.slider}>
            <div className={style.sliderContainer}>
                {expandedCardIndex !== null && (
                    <div className={style.navigation}>
                        <button onClick={prevCard} disabled={expandedCardIndex === 0}>
                            <ArrowBackIosIcon />
                        </button>
                        <button onClick={nextCard} disabled={expandedCardIndex === cards.length - 1}>
                            <ArrowForwardIosIcon />
                        </button>
                    </div>
                )}
                <div className={style.cardContent}>
                    {cards.map((card, index) => (
                        <div
                            key={card.id}
                            className={`${expandedCardIndex === index ? '' : style.cards}`}
                            onClick={() => handleCardClick(index)}
                        >
                            <div className={`${expandedCardIndex === index ? style.cardtitleContent : style.cardtitle}`}>
                                {expandedCardIndex === index ? '' : (
                                    <>
                                        <img src={card.img} className={style.cardImg} alt={card.title} />
                                        <p>{card.title}</p>
                                    </>
                                )}
                            </div>
                            {expandedCardIndex === index && (
                                <div className={style.carddescription}>
                                    <div className={style.cardHeader}>
                                        <img src={card.img} className={style.cardHeaderImg} alt={card.title} />
                                        <div className={style.cardHeaderDesc}>
                                            <h6>{card.title}</h6>
                                            <p>{card.desg}</p>
                                        </div>
                                    </div>
                                    <p>{card.description}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            </div>
            <div className={style.getStarted}>
                <div className={style.getStartedContent}>   
                    <div className={style.getStartedContentBg}>              
                    
                    <h2><b> Get Started Now</b></h2>
                    <p> Lorem Ipsum is simply dummy text of the printing and type setting industry has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to on people goes under takern goes on </p>
                <button onClick={handleNavigate}>Start Now</button>
                </div>
                </div>

            </div>
        </div>
    );
};

export default CardSlider;