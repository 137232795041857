import { useSelector } from "react-redux";
import {
  FetchAllSessions,
  FetchSessions,
  FetchUcoveringSessions,
} from "../../../Api";
import styles from "../../../styles/dashboardHome.module.css";
import { useEffect, useState } from "react";
import { cdn_Link } from "../../../config";
import dayjs from "dayjs";
const Home = () => {
  const cookie: any = useSelector((state: any) => state.user.user.token);
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const [Upcomingdata, setUpcomingData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchSessions = async () => {
    FetchUcoveringSessions({ filter: 1, item_per_page: 4, cookie: cookie })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setData(x);
      })
      .catch((err) => console.log(err));
  };

  const fetchUpcomingSessions = async () => {
    FetchAllSessions({ filter: 2, search: "", cookie: cookie })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...Upcomingdata };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setUpcomingData(x);
      })
      .catch((err) => console.log(err));
  };

  // const HandleDurationShow = ({ sessions }: { sessions: any[] }) => {
  //   const totalDuration = sessions.reduce((accumulator, element) => {
  //     return accumulator + (element?.duration || 0);
  //   }, 0);

  //   const hours = Math.floor(totalDuration / 60); // Get whole hours
  //   const minutes = totalDuration % 60; // Get remaining minutes

  //   return `${hours} Hr ${minutes} Min`;
  // };

  // const HandleFirstSession = ({ sessions }: { sessions: any[] }) => {
  //   if (!sessions.length) return null;
  //   const firstSession = sessions.sort(
  //     (a, b) =>
  //       new Date(a.startdate).getTime() - new Date(b.startdate).getTime()
  //   )[0];

  //   return firstSession?.startdate || null;
  // };

  useEffect(() => {
    fetchSessions();
    fetchUpcomingSessions();
  }, []);

  return (
    <>
      <div className={styles.mainContainer}>
        {/* first */}
        <div className={styles.firstContainer}>
          <div className={styles.menuesBox}>
            <div className={styles.item}>
              {/* box 1 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  CLIENT
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/dash-user.png"
                  alt=""
                />
              </div>
              <div>
                <h5>450</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/dash-upArrow.png" alt="" />
                <span> +5.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 2 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  SESSIONS
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/carbon_headphones.png"
                  alt=""
                />
              </div>
              <div>
                <h5>56</h5>
              </div>
              <div>
                <img src="/images/Coach/dash-upArrow.png" alt="" />
                <span> +5.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 3 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  COACHING HOURS
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ width: "24px" }}
                  src="/images/Coach/hour-glass.png"
                  alt=""
                />
              </div>
              <div>
                <h5>1299</h5>
              </div>
              <div>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 4 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  EARNINGS THIS MONTH
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ height: "33px" }}
                  src="/images/Coach/money-bag.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$1250</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
          </div>
          <div className={styles.ChartGraph}>
            <div className={styles.headLine}>
              <h5>Total Sessions</h5>{" "}
            </div>
            <div>
              <div className={styles.selectBar}>
                <select name="year" id="year">
                  <option value="year">This Year</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* second */}
        <div className={styles.middleContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" />{" "}
                Uncovering Session
              </h3>
            </div>
            <div>
              <h4 style={{ color: "#1C74BC", fontSize: "16px" }}>View All</h4>
            </div>
          </div>
          <div className={styles.tables}>
            <table>
              <thead>
                <tr>
                  <th>client name</th>
                  {/* <th>session type</th> */}
                  <th>Session Date</th>
                  <th>View Report</th>
                  <th>Join Session</th>
                </tr>
              </thead>
              <tbody>
                {data.history?.map((elem: any, ind: number) => (
                  <tr key={`${ind}_${elem._id?.coachee}`}>
                    <td className={styles.img_text}>
                      <div>
                        <img
                          src={
                            elem?.CoacheeDetails?.profileImg
                              ? `${cdn_Link}/${elem?.CoacheeDetails?.profileImg}`
                              : "/images/Coach/pic-placeholder.png"
                          }
                          alt="User Img"
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div className={styles.names}>
                        {elem?.CoacheeDetails?.name}
                      </div>
                    </td>
                    <td>
                      {elem?.appointmentDate
                        ? dayjs(elem?.appointmentDate).format(
                            " MMM DD, YYYY hh:mm A"
                          )
                        : "-"}
                    </td>
                    <td>View Report</td>
                    <td>Join Session</td>
                    {/* <td>{HandleDurationShow({ sessions: elem?.sessions })}</td> */}
                    {/* <td>{elem?.sessions.length}</td> */}
                    {/* <td>
                      {dayjs(
                        HandleFirstSession({ sessions: elem?.sessions })
                      ).format("MMM DD,YYYY hh:mm A")}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* third */}
        <div className={styles.thirdContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" /> Upcoming
                Appointments
              </h3>
            </div>
            <div>
              <h4 style={{ color: "#1C74BC", fontSize: "16px" }}>View All</h4>
            </div>
          </div>
          <div className={styles.tables}>
            <table>
              <thead>
                <tr>
                  <th>client name</th>
                  <th>session type</th>
                  <th>duration</th>
                  {/* <th>earnings</th> */}
                  <th>date & time</th>
                  <th>reschedule</th>
                  <th>action</th>
                </tr>
              </thead>
              <tbody>
                {Upcomingdata.history?.map((elem: any, ind: number) => (
                  <tr key={`${ind}_${elem._id?.coachee}`}>
                    <td className={styles.img_text}>
                      <div>
                        <img
                          src={
                            elem?.CoacheeDetails?.profileImg
                              ? `${cdn_Link}/${elem?.CoacheeDetails?.profileImg}`
                              : "/images/Coach/pic-placeholder.png"
                          }
                          alt="User Img"
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div className={styles.names}>
                        {" "}
                        {elem?.CoacheeDetails?.name}
                      </div>
                    </td>
                    <td>Type here</td>
                    <td>{elem?.duration} Min</td>
                    <td>
                      {" "}
                      {dayjs(elem?.startdate).format("MMM DD,YYYY hh:mm A")}
                    </td>
                    <td>
                      <img src="/images/Coach/reschedule.png" alt="" />
                    </td>
                    <td>
                      <img src="/images/Coach/action.png" alt="" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
