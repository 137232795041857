import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fetchCoacheeEnrollProgram, fetchCoacheeProgram } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import ShowProgram from "./Program/ShowProgram";
import LiveProgram from "./Program/LiveProgram";
import dayjs from "dayjs";
import EnrollProg from "./Program/EnrollProg";

const Programs = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [programData, setprogramData] = useState([]);
  const [progImg, setProgImg] = useState("")
  const [activeTab, setActiveTab] = useState(0);
  const[program_type,setProgram_type]=useState(1)

  const [progType, setProgType] = useState(1)
  const [progId, setProgId] = useState(1)
  const[page,setPage]=useState(1);
  // const program_type = 2;
  // alert(program_type)  
  useEffect(() => {
    progType == 3 ?
      fetchCoacheeEnrollProgram(cookie, program_type,page)
        .then((result: any) => {
          setprogramData(result.data.history)
          setProgImg(result.data.history)
        
        }
        )
        .catch((error: any) => {
          console.error("Error fetching Program data:", error);
          notifyError(error);
        })
      :
      fetchCoacheeProgram(cookie, progType,page)
        .then((result: any) => {

          setprogramData(result.data.history)
        })
        .catch((error: any) => {
          console.error("Error fetching Program data:", error);
          notifyError(error);
        });

  }, [cookie, progType,program_type,page]);
  const handlePreviousPage = async () => {
    if (page > 1) {
      await setPage(page - 1);
    }
  };

  const handleNextPage = async () => {
    if (programData.length > 0) {
      await setPage(page + 1);
    }
  };

  const handleShow = (uId: any) => {
    setActiveTab(1)
    setProgId(uId)



  }
  console.log("this is programImg", progImg)
  return (<>
    {activeTab == 0 && (<>

      <div className={styles.dashcontainer}>
        <div className={styles.UpcomingContainer}>
          <div className={styles.UpcomingPList}>
            <div className={styles.UpcomingPListLeft}>
            <p onClick={() => setProgType(1)} className={`${styles.tab1} ${progType === 1 && styles.setliactive}`}>Live Program</p>
            <p onClick={() => setProgType(2)} className={`${progType === 2 && styles.setliactive}`}>Recorded Program</p>
            <p onClick={() => setProgType(3)} className={`${progType === 3 && styles.setliactive}`}>Enrolled Program</p>
            </div>
            <div className={styles.UpcomingPListRight}>
              {progType==3?(<>
                <select onChange={(e:any) => setProgram_type(e.target.value)}className={styles.PorgrmaTypes}>
  <option value="1">Live Programs</option>
  <option value="2">Recorded Programs</option>
</select>

</>):(<></>)}
            </div>
          </div>

        </div>
        <div className={styles.DashForRecom}>
          <div className={styles.Recommond}>

            {programData.length > 0 ? (programData.map((pdata: any) => <>


              <div className={styles.coaching}>
                <img
                  src={`https://dxe2g9i1k0e2c.cloudfront.net/${pdata.programImg || pdata.Program_Details?.programImg}`}
                  className={styles.coachingImg}
                />
               {progType==3 ? ( <div className={styles.coachingTxt} onClick={() => handleShow(pdata.Program_Details._id)}>
                  <p>{pdata.name || pdata.Program_Details?.name}</p>
                  <div className={styles.Description}>

                  </div>
                  <div className={styles.ProgramDate}>
                    <p><CheckCircleIcon /> {dayjs(pdata.createdAt.slice(0, 10)).format('DD MMM YYYY')}</p>
                  </div>
                </div>):( <div className={styles.coachingTxt} onClick={() => handleShow(pdata._id)}>
                  <p>{pdata.name || pdata.Program_Details?.name}</p>
                  <div className={styles.Description}>

                  </div>
                  <div className={styles.ProgramDate}>
                    <p><CheckCircleIcon /> {dayjs(pdata.createdAt.slice(0, 10)).format('DD MMM YYYY')}</p>
                  </div>
                </div>)}
              </div>



            </>)) : (<>No  Any Program Enrolled !</>)}
          </div>
        </div>
        <div className={styles.pagination}>
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </button>
        <button onClick={handleNextPage} disabled={programData.length === 0}>
          Next
        </button>
      </div>
      </div>


    </>)} {activeTab === 1 && progType === 2 && (<ShowProgram progId={progId} />)}
    {activeTab === 1 && progType === 1 && (<LiveProgram progId={progId} />)}
    {activeTab==1 && progType===3 && (<EnrollProg progId={progId} program_type={program_type}/>)}



  </>)
}
export default Programs;