import { useSelector } from "react-redux";
import style from "./showPro.module.css"
import { RootState } from "../../../../redux/store";
import { useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { chapterMarkComplete, fetchCoacheeEnrollProgramDetails } from "../../../../Api";
import { notifyError } from "../../../../Notify";
type propType = {
    progId: any
}
const ShowRecordProgram = ({ progId }: propType) => {
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [chapterData, setChapterData] = useState<any[]>([])
    const [chapterId, setChapterId] = useState('')
    const [activeTb, setActiveTb] = useState(false)
    const [ongoingChapterIndex, setOngoingChapterIndex] = useState<number | null>(null); // Track ongoing chapter

    useEffect(() => {
        fetchCoacheeEnrollProgramDetails(cookie, progId)
            .then((res: any) => {
                console.log("program ChapterInfo", res)
                setChapterData(res.data.history)

            })

    }, [cookie, activeTb, ongoingChapterIndex])
    const getFileType = (url: string): string => {
        const extension = url.split('.').pop()?.toLowerCase();
        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'png':
            case 'jpg':
            case 'jpeg':
                return 'image';
            case 'mp4':
            case 'mov':
                return 'video';
            default:
                return 'unknown';
        }
    };

    const renderResource = (res: any, resIndex: number) => {
        const url = getFileType(res);
        const fileType: any = url;
        console.log(fileType)
        if (fileType === 'application/pdf') {
            return (
                <a key={resIndex} href={`https://dxe2g9i1k0e2c.cloudfront.net/${res}`} target="_blank" rel="noopener noreferrer">
                    View PDF
                </a>
            );
        } else if (fileType === 'image') {
            return (
                <img
                    key={resIndex}
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${res}`}
                    alt={`Resource ${resIndex}`}
                    className={style.resourcesImg}
                />
            );
        } else if (fileType === 'video') {
            return (
                <video key={resIndex} controls style={{ maxWidth: '100%', height: 'auto' }}>
                    <source src={`https://dxe2g9i1k0e2c.cloudfront.net/${res}`} type={fileType} />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return <p key={resIndex}>Unsupported resource type</p>;
        }
    };

    const markComplete = (e: any, cId: any, pId: any,index:any) => {
        e.preventDefault();
        setChapterId(cId)
        chapterMarkComplete(pId,cId,cookie)
        .then((res:any)=>{
            setOngoingChapterIndex(index + 1)
            setActiveTb(true)

        })
        setActiveTb(false)
       


    }
    return (<>
        <div className={style.showRecord}>
            <div className={style.showRecordContent}>
                {/* <div className={style.showRecordVideo}>

        </div> */}


                {chapterData.length > 0 ? (chapterData.map((chapter: any, index: any) => (
                    <>
                        {chapterId == chapter._id ? (<>

                            <h5><b><i> {index + 1}. {chapter.title} </i></b> </h5>
                            <h6>What we are going to learn</h6>
                            <p>  <br />{chapter.expected}</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            <b>Resources</b>
                            {chapter.resource.map((res: any, resIndex: number) => renderResource(res, resIndex))}


                        </>) : (<></>)}

                    </>
                ))) : (<>No Chapter Created !</>)}

                <br />


            </div>
            <div className={style.showRecordList}>
                <table>
                    <tr>
                        <th>SESSION TOPIC</th>
                        <th>WHAT ARE WE GOING TO LEARN</th>
                        {/* <th>EXPECTED DURATION</th> */}
                        <th>STATUS</th>

                    </tr>

                    {chapterData.length > 0 ? (chapterData.map((chapter: any, index: any) => (
                        <tr>
                            <td className={style.chapterHead}><p className={style.chapterHeadIcon}>{(chapter.title.split(" ").length > 1 ? (chapter.title.split(" ").map((word: any) => word.charAt(0)).join("").substring(0, 2).toUpperCase()) : (chapter.title.slice(0, 2).toUpperCase()))}  </p> <p>{chapter.title}</p></td>
                            <td>{chapter.expected.slice(0, 30)}</td>
                            {/* <td>{chapter.duration} Hrs</td> */}

                            <td>
                                {chapter.isCompleted === false ? (
                                    <div 
                                        onClick={(e: any) => {
                                            if (index === 0 || chapterData[index - 1].isCompleted) {
                                                markComplete(e, chapter._id, chapter.programId,index);


                                            }
                                            else {
                                                notifyError("Please Complete Previous Chapters!")
                                            }
                                        }}>

                                        {ongoingChapterIndex === index ? (
                                            <p className={style.ongoingState}> <AccessTimeIcon /> Ongoing</p>
                                        ) : (
                                            <p className={style.pendingState}> <AccessTimeIcon /> Pending</p>
                                        )}
                                    </div>
                                ) : (
                                    <p className={style.completeState}
                                        onClick={(e: any) => markComplete(e, chapter._id, chapter.programId,index)}>
                                        <CheckCircleIcon /> Completed
                                    </p>
                                )}
                            </td>
                        </tr>
                    ))) : (<>No Chapter Created !</>)}

                </table>
            </div>
        </div>
    </>)
}
export default ShowRecordProgram;