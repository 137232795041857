import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { fetchCoacheeEnrollProgramDetails, fetchCoacheeProgramByID, fetchCoacheeSession } from "../../../../Api";
import styles from "./showPro.module.css"
import styled from "../CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import dayjs from "dayjs";
import ShowRecordProgram from "./ShowRecordProgram";
import { Button } from "antd";
type proptype={
    progId:any,
    program_type:any
}
const EnrollProg=({progId,program_type}:proptype)=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [programDetails,setprogramDetails]=useState<any>()
    const[chapterData,setChapterData]=useState<any[]>([])
    const[activeTabs,setActiveTabs]=useState(1)
    const [CoachingData,setCoachingData]=useState<any[]>([])
useEffect(()=>{
    fetchCoacheeProgramByID(cookie,progId)
    .then((res:any)=>{
        console.log("programInfo",res)
        setprogramDetails(res.data)
       
    })

},[cookie])
useEffect(()=>{
  fetchCoacheeSession(1,cookie)
  .then((result:any)=>{
   
    
    setCoachingData(result.history)
    
  })
  .catch((error:any)=>{
    console.log("from Card",error)
  })
},[cookie])
useEffect(()=>{
    fetchCoacheeEnrollProgramDetails(cookie,progId)
    .then((res:any)=>{
        console.log("program ChapterInfo",res)
        setChapterData(res.data.history)
       
    })

},[cookie])
  // useEffect(()=>{
  //   fetchCoachprogramByID(coachId,cookie)
  //   .then((res:any)=>{
  
  //     console.log("data of program By coach",res.data)
  //     // setProgramData(res.data.history)
  //   })
  // },[cookie])
    return(<>
   
    {/* {progId}  */}
    <div className={styles.Allcontent}>
    <div className={styles.ProgContent}>
          <div className={styles.ProgContentLeft}>
            <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${programDetails?.programImg}`} className={styles.ProgImg} />
            <div>
              <h6>{programDetails?.name}</h6>
              <p>{dayjs(programDetails?.createdAt.slice(0, 10)).format('DD MMM YYYY')}</p>
            
              <p></p>
            </div>
          </div>
          <div className={styles.ProgContentRight}>
            {program_type==1 && (<button>
                Back To Main
            </button>)}
            {program_type==2 && (<>
             {activeTabs==1 && (<><button onClick={()=>setActiveTabs(2)}>Continue Learning </button></>)} 
             {activeTabs ==2 && (<><button onClick={()=>setActiveTabs(1)}>Back To Main </button></>)}
             </>)}
            
            </div>
          </div>


          <div className={styles.ProgDescr}>
          <div className={styles.ProgramDesc}>
            <br/>
            <br/>
          <h6> Description </h6>
          <p>{programDetails?.description}</p>
            </div>
            </div>
{program_type==1 && (<h6>Selected Coach </h6>)}
<br/>
            <br/>

{activeTabs==1 && (<>
    <h5>Upcoming Session</h5>
   {program_type==1 &&(<>
   
    <div className={styled.Recommond}>

    {
  CoachingData.length > 0 ? (
    CoachingData.some(cdata => cdata.pId === progId) ? (
      CoachingData.map((cdata: any) => (
        <>
          {cdata.pId === progId && (
            <div className={styled.coaching} key={cdata.pId}>
              <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.coach?.profileImg}`}
                className={styled.coachingImg}
              />
              <div className={styled.coachingTxt}>
                <p>{cdata.coach?.name}</p>
                <div className={styled.coachEmail}>
                  <span>{cdata.coach?.email.slice(0, 14)}..</span>
                  <Button className={styled.coachButtonJoin1}>
                    <a href={cdata.join_url} target="_blank" rel="noopener noreferrer">Join</a>
                  </Button>
                </div>
                <div className={styled.coachtime}>
                  <span>
                    <AccessTimeIcon style={{ fill: "#FF5733" }} />
                    {cdata.starttime}
                  </span>
                  <span>
                    <CalendarMonthIcon style={{ fill: "#FF5733" }} />
                    {dayjs(cdata.date.slice(0, 10)).format('DD MMM YYYY')}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ))
    ) : (
      <p>No appointment scheduled</p> 
    )
  ) : (
    <p>No Any Session selected!</p>
  )
}
</div>
   </>
)}

{program_type==2 &&(<>
<table>
    <tr>
        <th>SESSION TOPIC</th>
        <th>WHAT ARE WE GOING TO LEARN</th>
        {/* <th>EXPECTED DURATION</th> */}
        <th>CREATED DATE</th>
        <th>STATUS</th>

    </tr>
  
          {chapterData.length>0?(chapterData.map((chapter:any)=>(
 <tr>
<td className={styles.chapterHead}><p className={styles.chapterHeadIcon}>{(chapter.title.split(" ").length>1?(chapter.title.split(" ").map((word:any) => word.charAt(0)).join("").substring(0,2).toUpperCase() ):(chapter.title.slice(0,2).toUpperCase()))}  </p> <p>{chapter.title}</p></td>
<td>{chapter.expected.slice(0,30)}</td>
<td>{dayjs(chapter.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
{/* <td>{chapter.duration} Hrs</td> */}


<td>{chapter.isCompleted==false ? (<p onClick={()=>setActiveTabs(2)}  className={styles.pendingState}><WatchLaterIcon/> Continue</p>):(<p onClick={()=>setActiveTabs(2)}  className={styles.completeState}><CheckCircleIcon/> Completed</p>)}</td>


</tr>
      ))):(<>No Chapter Created !</>)}
  
</table>
</>)}
</>)}
{activeTabs ==2 && <ShowRecordProgram progId={progId}/>}


    </div>

    
    </>)
}
export default EnrollProg;      