import { useEffect, useState } from "react";
import GeTab1 from "./group-engagement/GE-Tab1";
import GeTab2 from "./group-engagement/GE-Tab2";
import GeTab3 from "./group-engagement/GE-Tab3";
import styles from "./group-engagement/group.module.css";
import Style from "../../../../src/styles/group.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchCoachGroup } from "../../../Api";
import { notifyError } from "../../../Notify";

interface SetupProps {}
const Group = ({}) => {
  const [groupId, setGroupId] = useState("");
  return (
    <>
      <div className={Style.Artist}>{/* {Tab Container} */}</div>
      <GeTab1 />
    </>
  );
};

export default Group;
