import style from "./profiledata.module.css";
import styles from "../../CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { fetchCoacheeSession } from "../../../../../Api";
import { Button } from "antd";
const Webinars=()=>{
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.profileSetup);

  const cookie: any = user?.token;
  const [CoachingData,setCoachingData]=useState<any[]>([])
  useEffect(()=>{
    fetchCoacheeSession(1,cookie)
    .then((result:any)=>{
     
      console.log("Card details",result)
      setCoachingData(result.history)
      
    })
    .catch((error:any)=>{
      console.log("from Card",error)
    })
  },[cookie])
    return(
        <>
        <div className={style.UserProfile}>
        <div className={styles.Recommond}>
          
        

          {
            CoachingData.length>0?(CoachingData.slice(0,2).map((cdata:any)=>(<>
            <div className={styles.coaching}>
             <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.coach?.profileImg}`} className={styles.coachingImg} />
             <div className={styles.coachingTxt}>
                          <p>{cdata.coach?.name}</p>
                          <div className={styles.coachEmail}>
                            <span> {cdata.coach?.email.slice(0,14)}..</span>
                            <Button className={styles.coachButtonJoin1}> <a href={cdata.join_url} target="_blank" rel="noopener noreferrer">Join</a></Button>
                          </div>
                          <div className={styles.coachtime}>
                            <span>
                              <AccessTimeIcon style={{ fill: "#FF5733" }} />
                              {cdata.starttime}
          
                            </span>
                            <span>
                              {" "}
                              <CalendarMonthIcon style={{ fill: "#FF5733" }} />{dayjs(cdata.date.slice(0,10)).format('DD MMM YYYY')}
                            </span>
                          </div>
                          </div>
                  
                          </div>
            </>))):(<>No Any Webinar selected!</>)
          }
          
          </div>
   
          </div>
         {/* </div> */}
       
        </>
    )

}
export default Webinars;