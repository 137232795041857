import { useSelector } from "react-redux";
import style from "./profiledata.module.css"
import styles from "../../CSS/dashboardHome.module.css";
import styled from "./../../../Admin/wrapper/wrapper.module.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { RootState } from "../../../../../redux/store";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, TextField, Typography } from "@mui/material";
import { UpdateCoachPersonalInfo } from "../../../../../Api";
import { notifyError, notifySuccess } from "../../../../../Notify";
const UserProfile=()=>{
    
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose= () => setOpen(false);
    const str: any = user?.name;
    const abc:any=str.split(" ");
    
    const [fname,setfname]=useState(abc[0])
    const [lname,setlname]=useState(abc[1])
    const [email,setEmail]=useState(user?.email)
    const[mono,setmono]=useState('')
    const[profile,setProfile]=useState('')
    const[address,setAddress]=useState('')
    const [previewImage, setPreviewImage] = useState("");
    const handleProfileClick = () => {
        const fileInput = document.getElementById("fileInput");
        if (fileInput) {
          fileInput.click();
        }
      }; 
      const handleProfile = (e: any) => {
        const file = e.target.files[0];
       
        console.log("files", file);
        setProfile(file)
    
        const imageURL = URL.createObjectURL(file);
        setPreviewImage(imageURL);
      };
      const handleSubmit=(e:any)=>{
        e.preventDefault();
      
        UpdateCoachPersonalInfo(fname,lname,mono,address,profile,cookie)
        .then((res:any)=>{
            notifySuccess(res?.message)
        })
        .catch((err:any)=>{
            notifyError(err.response.data.message)
        })
      }
return(
    <>
    <div className={style.UserProfile}>
        <h6>Personal Info <EditOutlinedIcon className={style.editIcon} onClick={()=>handleOpen()}/></h6>
        <div className={style.UserDataheadContent}>
        <div className={style.UserDatahead}>
        <p>First Name</p>
        <p>Last Name</p>
      <p>Email</p>
        <p>Mono</p>
        <p>Address</p>
        <p>City</p>
        </div>
        <div className={style.UserData}>
        <p>{fname}</p>
        <p>{lname}</p>
        <p>{email}</p>
        <p>796-451-1071</p>
        <p>41, willson street, near joren, New york city, ny.</p>
        <p>New Yark</p>
        </div>
        </div>
    </div>
    <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                Update Your Profile   
                              </Typography>
                <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              >
                <form onSubmit={handleSubmit}>
                 <div className={styled.ProgramProp}>
                    <div className={styled.onlinePro} >
                    <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
                onChange={handleProfile}
              id="fileInput"
              name="profile"
            />

            {!previewImage ? (
              <>
                <img
                  src={`https://dxe2g9i1k0e2c.cloudfront.net/${user?.profileImg}`}
                  className={styled.progProfile}
                />
                <div className={styled.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
              </>
            ) : (
              <>
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styled.MainImgPre}
                />
                <div className={styled.IconImg}>
                  <img
                    src="/images/svg/Coach/group-8291.svg"
                    onClick={handleProfileClick}
                  />
                </div>
               
              </>
            )}
            </div>
            <div className={styles.DisplaytextField}>
                <TextField
            type="text"
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            name="name"
            value={fname}
            onChange={(e: any) => setfname(e.target.value)}
            className={styles.Inputfield}
            InputProps={{
              className: `${styled.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
            <TextField
            type="text"
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            name="lname"
            value={lname}
            onChange={(e: any) => setlname(e.target.value)}
            className={styles.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
          </div>
          <div className={styles.DisplaytextField}>
                <TextField
            type="text"
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            name="email"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            className={styles.Inputfield}
            InputProps={{
              className: `${styled.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
            <TextField
            type="text"
            id="outlined-basic"
            label="MONO "
            variant="outlined"
            name="mono"
            value={mono}
            onChange={(e: any) => setmono(e.target.value)}
            className={styles.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
          </div>
          <TextField
            type="text"
            id="outlined-basic"
            label="Address "
            variant="outlined"
            name="address"
            value={address}
            onChange={(e: any) => setAddress(e.target.value)}
            className={styles.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />
            </div>
            <button  className={styled.SaveButton}>
              Update
            </button>
            </form>
              </Typography>
                </Box>
                </Modal>
    </>
)
}

export default UserProfile;