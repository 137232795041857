import { useEffect, useState } from "react";
import { BookUncoveringSession, CreateSessionByCoachee, fetchCard, fetchCoachByCoachee, fetchCoachDataForCoachee, fetchCoachprogramByID, GetUncoveringSession } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError, notifySuccess } from "../../../../Notify";
import EmailIcon from '@mui/icons-material/Email';
import styles from "../CSS/dashboardHome.module.css";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Box, Button, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddCardIcon from '@mui/icons-material/AddCard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { PaymentModal } from "../../../modal/PaymentModal";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  coachId: any;

};

const RecommendCoach = ({ setActiveTab, coachId }: propType) => {
    dayjs.extend(utc);
    dayjs.extend(customParseFormat);
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [comPow, setcomPow] = useState<string[]>([])
  const [cardId, setCardId] = useState("")
  const [coachdata, setCoachData] = useState()
 
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose= () => setOpen(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<any[]>([]);
  const [Typo, setTypo] = useState(0)
  const[programData,setProgramData]=useState<any[]>([])
const[session,setSession]=useState<any>()
  const [appDetails, setAppDetails] = useState({
    "coachId": "",
    "pId": "",
    "gId": "",
    "starttime": "",
    "endtime": "",
    "appType": "",
    "date": "",
  })
  const [data, setData] = useState<any>(
    //   {
    //   name: "",
    //   email: "",
    //   profile: "",
    //   timeZone: "",
    //   avail: [],
    // }
  )
useEffect(()=>{
  fetchCoachprogramByID(coachId,cookie)
  .then((res:any)=>{

    console.log("data of program By coach",res.data)
    setProgramData(res.data.history)
  })
},[cookie])

  // const handleClose = () => ;
  useEffect(() => {
    fetchCard(cookie)
      .then((result: any) => {
        setCards(result.data)
      })
      .catch((error: any) => {
        console.log("from Card", error)
      })
  }, [cookie])


  useEffect(() => {
    fetchCoachDataForCoachee(cookie, coachId)
      .then((result: any) => {
        setData(
          // name: result.data.coach.uid.name,
          // email: result.data.coach.uid.email,
          // profile: result.data.coach.uid.profileImg,
          // timeZone: result.data.coach.timeZone,
          // avail: result.data.availability,
          result.data
        )
        setcomPow(result.data.coach.commpower)
        setLoading(false)
      })


      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error);
      });
  }, [cookie, coachId])
  useEffect(()=>{
    GetUncoveringSession(cookie)
    .then((res:any)=>{
        setSession(res.data)
        console.log("this is session Data by coachhe",res.data)
     
    })
  },[cookie])

  const bookUncovering = (e: any,coachId:any) => {
   e.preventDefault();
   BookUncoveringSession(coachId,cookie)
   .then((res:any)=>{
    notifySuccess("Session Scheduled Successfully")
   })
   .catch((err:any)=>{
    notifyError(err.response.data.message)
   })

  }
  



  return (
    !loading ? (<div className={styles.dashcontainer}>
      <div className={styles.UpcomingContainer}>


        <div className={styles.recommendContainer}>
          <div className={styles.recommendLeft}>
            {data?.coach.uid.profileImg ? (<img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${data?.coach.uid.profileImg}`}
              className={styles.coachImg}
            />) : (
              <h4 className={styles.coachIcon}>{data?.coach.name?.slice(0, 2).toUpperCase()||null}</h4>
            )}

            <span>
              <h5>{data.coach.uid.name || 'User'}</h5>
              <p className={styles.dataIcoCoach}><span><EmailIcon /></span> {data.coach.uid.email}</p>
            </span>
          </div>
          <div className={styles.recommendRight}>
       
            {session?.coach==data.coach.uid._id?(
            session?.isApproved==true?(<>
            <button className={styles.TypoButton} onClick={()=>handleOpen()}><span><EditCalendarIcon /></span>Start Session </button>
            </>):(<><button className={styles.TypoButton1}><span><EditCalendarIcon /></span>Waiting for Approval  </button></>)
            ):<> <button className={styles.TypoButton} onClick={(e:any)=>bookUncovering(e,data.coach.uid._id)}><span><EditCalendarIcon /></span> Book Uncovering Session</button></>}
          { session?.isApproved==true?(
            <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.BoxStyle}>
              <span
                className={styles.HandleClose}
                onClick={() => handleClose()}
              >
                <CloseTwoToneIcon className={styles.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
               Uncovering Session 
              </Typography>
              <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              >
             <div className={styles.recommendContainer}>
          <div className={styles.recommendLeft}>
            {data?.coach.uid.profileImg ? (<img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${data?.coach.uid.profileImg}`}
              className={styles.coachImg}
            />) : (
              <h4 className={styles.coachIcon}>{data?.coach.name?.slice(0, 2).toUpperCase()}</h4>
            )}

            <div>
              <h5>{data.coach.uid.name || 'User'}</h5>
              <p className={styles.dataIcoCoach}> {data.coach.uid.email}</p>
            </div>
          </div>
         
          </div>
          <div className={styles.ScheduledAppContent}>
        
            <p> Your Appointment Scheduled On</p>
            <h6>{dayjs(session?.appointmentDate.slice(0,10)).format('DD MMM YYYY') || null}</h6> 
           <h6>{dayjs.utc(session?.appointmentDate).format('HH:MM A')|| null}</h6> 
           <button className={styles.TypoButtonBtn}><a href={session?.meetingLink|| null} target="_blank" rel="noopener noreferrer">Meeting Link</a></button>
          </div>
              </Typography>
              </Box>
              </Modal>
              ): (<></>)}
           
          </div>

        </div>

        <hr />
        <div>
          <b>About Coach</b>

          <p className={styles.dataColors}>
            Lorem Ipsum is simply dummy text of the printing and type setting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the
            1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
            recently with desktop publishing software like Aldus PageMaker including versions of
            Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and type setting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>
        </div>

        <div>
          <br />
    
       <b>Categories that can help</b>
          <b>{comPow.length > 0 ? (comPow.map((compow: any) => (<>
            
            <p className={styles.comPowStyle}>
              {compow.split(',').map((part: string, i: number) => (
                <p key={i} className={styles.comPowStyles}>
                  {part.trim()}
                </p>
                
              ))}
            </p>
          </>))) : (<>No Data found!</>)}</b>
          <br/>
          <h6><b>Introduction Video </b></h6>
          <video src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.coach.introvideo}`} className={styles.introvideo}
                        controls/>
          <h6><b>Entitled With Programs</b></h6>
       <div className={styles.DashForRecom}>
       
       <div className={styles.Recommond}>
          <p>{programData.length>0?(programData.map((program:any)=>(

            <>
               <div className={styles.coaching}>
               <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${program.programDetails?.programImg}`}
                        className={styles.coachingImg}
                      />
                      <div className={styles.coachingTxt} >
                      <p>{program.programDetails?.name}</p>
                      <span>{program.programDetails?.description.slice(0,60) || null}..</span>
                      </div>
               </div>
            </>
          ))):(<>Please Select Programs</>)}</p>

</div>
</div>
          
        </div>
        <div>
  
        </div>

      </div>

    </div>) : <></>
  )

}
export default RecommendCoach;