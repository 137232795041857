import "./App.css";
import { Routes, Route } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Setup from "./pages/Setup";
import ForgotPassword from "./pages/Forgot_Password";
import LandingWrapper from "./Landing/wrapper/LandingWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ZoomAPI from "./components/ZoomInt/ZoomAPI";
import ErrorBoundary from "./pages/ErrorBoundary ";

const App = () => {
  const notify = () => {
    toast.success("Success message!", { autoClose: 5000 });
    toast.error("Error message!", { autoClose: 8000 });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<LandingWrapper />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/verify" element={<ForgotPassword />} />
          <Route path="/setup" element={<Setup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/meet" element={<ZoomAPI />} />
        </Routes>
      </ErrorBoundary>
    </>
  );
};

export default App;
