import { TextField } from "@mui/material";
import style from "../../../../styles/setting.module.css";
import { FetchCoachInfo } from "../../../../Api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
const SetTab3 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [data, setData] = useState({
    yearExp: "",
    style: "",
    model: "",
    perfect: "",
    challenges: "",
    currentprofession: "",
  });

  const fetchCoachDetails = () => {
    FetchCoachInfo(cookie)
      .then((result: any) => {
        const {
          yearExp,
          style,
          model,
          perfect,
          challenges,
          currentprofession,
        }: any = result.data;
        setData({
          yearExp,
          style,
          model,
          perfect,
          challenges,
          currentprofession,
        });
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    fetchCoachDetails();
  }, []);

  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>Coaching Experience</header>
        <div className={style.tabformcontent}>
          <div className={style.leftabcontent}>
            <div className={style.inputtab3}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Years of Experience"
                variant="outlined"
                value={data.yearExp}
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1, borderRadius: "20px" }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                value={data.model}
                label="Methodology or model used when coaching"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                value={data.challenges}
                id="outlined-basic"
                label="Challenges/adversities faced as a coache"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
          <div className={style.rightabcontent}>
            <div className={style.inputtab2}>
              <TextField
                type="text"
                id="outlined-basic"
                value={data.style}
                label="Coaching style"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                value={data.perfect}
                label="Perfect Client"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                value={data.currentprofession}
                label=" Way stay current in coaching as a profession"
                variant="outlined"
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
        </div>
        <div className={style.formbutton}>
          <button>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab3;
