import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import {  CreateNewGroup, fetchAgroup, fetchCoach } from "../../../Api";
import styles from "./wrapper/wrapper.module.css";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import style from "./programs/programs.module.css"
import AddIcon from "@mui/icons-material/Add";
import FastRewindIcon from '@mui/icons-material/FastRewind';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Box, TextField } from "@mui/material";
import CreateAGroup from "./Group/CreateAGroup";
import DispGroup from "./Group/DispGroup";
import GroupInfo from "./Group/GroupInfo";
import { notifyError } from "../../../Notify";
const GroupManage = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [coachData, setCoachData] = useState<any>([]);
  const [coacheeData, setCoacheeData] = useState<any>([]);
  const [groupTopic, setgroupTopic] = useState("");
  const [activescreen, setActivescreen] = useState(2);
  const [assignedCoach, setassignedCoach] = useState<any[]>([]);
  const [assignedCoachee, setassignedCoachee] = useState<any[]>([]);
  const [groupData, setGroupdata] = useState<any>([]);
  const[ pid,setPid]=useState("")
  const[upd,setUpd]=useState(false)
  const [select,setSelect]=useState(1);
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;

  useEffect(() => {
    fetchCoach(cookie,1)
      .then((result: any) => {
        console.log("Coach data:", result);
        setCoachData(result.data.history);
        console.log("uid Data ", coachData);
      })
      .catch((err: any) => {
        console.error("Error fetching coach data:", err);
        notifyError(err.response.data.message);
      });
  }, [cookie]);
  useEffect(() => {
    fetchCoach(cookie,2)
      .then((result: any) => {
        console.log("Coach data:", result);
        setCoacheeData(result.data.history);
        setgroupTopic("")
        console.log("coachee Data ", coacheeData);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error.response.data.message);
      });
  }, [cookie]);

  const coachIds=JSON.stringify(assignedCoach);
  const coacheeIds=JSON.stringify(assignedCoachee)
  const programForm=(e:any)=>{
e.preventDefault();
CreateNewGroup(groupTopic,coachIds,coacheeIds,cookie)
.then((result: any) => {
  console.log(result);
  setUpd(prev => !prev);
  
  handleClose();
})

.catch((error) => {
  console.log(error);
});
}
console.log("coachee ID:",coacheeIds)
console.log("coach ID:",coachIds)
  
  const handleAddCoach = (coachId: string) => {
    if (!assignedCoach.includes(coachId)) {
      setassignedCoach([...assignedCoach, coachId]);
    }
  };
  const handleRemoveCoach = (coachId: string) => {
    setassignedCoach(assignedCoach.filter((id) => id !== coachId));
  };

  const handleAddCoachee = (coacheId: string) => {
    if (!assignedCoach.includes(coacheId)) {
      setassignedCoachee([...assignedCoachee, coacheId]);
    }
  };
  const handleRemoveCoachee = (coacheId: string) => {
    setassignedCoachee(assignedCoachee.filter((id) => id !== coacheId));
  };
  return (
    <div className={styles.mainContiner}>
      <div className={styles.searchT}>
        <div className={styles.searchT1}>
        <FastRewindIcon className={styles.searchTexIcon} onClick={()=>setActivescreen(2)}/>
        <input
          className={styles.container2}
          type="text"
          placeholder="Search Group here"
          style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
        /></div>
        <div className="addButton">
          <div className={styles.upgradeButton}>
            <Button onClick={handleOpen}>
              <AddIcon className={styles.upgradeBtn} />
              Add new Group
            </Button>
            <Modal  open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll',overflowY: 'scroll'}}>
             
              <Box className={styles.BoxStyle}>
                  <span onClick={handleClose} className={styles.HandleClose}>
                    <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                   <h3> Create New Group</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={styles.typoDesc}
                  >
                     <form className={styles.programForm} onSubmit={programForm}>
                    <TextField
            type="text"
            id="outlined-basic"
            label="Group Topic"
            variant="outlined"
            name="groupTopic"
            value={groupTopic}
            onChange={(e: any) => setgroupTopic(e.target.value)}
            className={styles.Inputfield}
            InputProps={{
              className: `${styles.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
          />

           <h6>Add Coaches</h6>
          <div className={styles.AddCoachList}>
          {coachData.length > 0 ? (
                coachData.map((coach: any, index: number) => (<>
                <div className={styles.CoachlstP}>
                <p>
                  {coach.profileImg?
                  (
                    <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                    className={styles.coachImg}
                  />
                  ):( <img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg2} />)}
               
                 {coach.name} <p className={styles.Coachlstemal}> {coach.email}</p></p>

<p >
{assignedCoach.includes(coach._id) ? ( <CloseTwoToneIcon className={styles.CoachlstIconClose}  onClick={() => handleRemoveCoach(coach._id)}/>)
:( <AddIcon className={styles.CoachlstIcon}  onClick={() => handleAddCoach(coach._id)}/>)}
 </p>
 
                 </div>
                </>
                ))
              ) : (
                <p>No coach data available</p>
              )}
          
          </div>
          <p>Add Coachee</p>
  <div className={styles.onlineRadioBtn1}> 
                      <p  onClick={()=>setSelect(1)} className={`${styles.RadioNonActive} ${select === 1 && styles.RadioActive}`}><p></p> By Mail </p>
                      <p className={`${styles.RadioNonActive} ${select === 2 && styles.RadioActive}`} onClick={()=>setSelect(2)}><p></p>By Excel File </p>
                      </div>

                      {select===2?(<>
                      <div className={style.addExcel}>
                      <div className={style.Filetext}>
            <FileCopyIcon  className={style.FileIcon}/>
            <h6>Upload File/Video</h6>
            <p className={style.Filep}>Lorem ipsom is simple dummy text that can people can that <br/> can under tool so can goes <br/>
              dotted on hiligter ipsom so not needed.</p>
            {/* <TextField
            type="file"/> */}
          </div>
         
                      </div>
                      </>):(
                        <>
<div className={styles.AddCoachList}>
          {coacheeData.length > 0 ? (
                coacheeData.map((coachee: any, index: number) => (<>
                <div className={styles.CoachlstP}>
                <p>
                  {coachee.profileImg?
                  (
                    <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${coachee.profileImg}`}
                    className={styles.coachImg}
                  />
                  ):( <img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg2} />)}
               
                 {coachee.name} <p className={styles.Coachlstemal}> {coachee.email}</p></p>

<p >
{assignedCoachee.includes(coachee._id) ? ( <CloseTwoToneIcon className={styles.CoachlstIconClose}  onClick={() => handleRemoveCoachee(coachee._id)}/>)
:( <AddIcon className={styles.CoachlstIcon}  onClick={() => handleAddCoachee(coachee._id)}/>)}
 </p>
 
                 </div>
                </>
                ))
              ) : (
                <p>No coach data available</p>
              )}
          
          </div>
          <button  className={styles.SaveButton}>
              Save
           
            </button>

                        </>
                      )}
          </form>
          

                  </Typography>

              </Box>
            </Modal>
          </div>
        </div>
      </div>

      {activescreen === 1 && <CreateAGroup setActivescreen={setActivescreen} />}
      {activescreen === 2 && <DispGroup setActivescreen={setActivescreen} upd={upd} setPid={setPid}/>}
      {activescreen === 3 && <GroupInfo  pid={pid} />}
    </div>
  );
};

export default GroupManage;
